import React, { Component } from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import LinkButton from 'src/components/link_button'
import styles from 'src/css/slices/partners.module.css'

class Partners extends Component {
  render = () => {
    const { feature_image, text, left_button_label, left_button_link, right_button_label, right_button_link, gradient_color_start, gradient_color_end } = this.props.primary
    const gradientColorStart = gradient_color_start || '#786dff'
    const gradientColorEnd = gradient_color_end || gradient_color_start || '#ecc3e7'
    const background = `linear-gradient(50deg,${gradientColorStart},${gradientColorEnd})`
    return (
      <div className={styles.wrapper} style={{ background }}>
        <div className={styles.partners}>
          <div className={styles.inner}>
            <div className={styles.featureImage}>
              <Image dpiRatio={2}>{feature_image}</Image>
            </div>
            <div className={styles.content}>
              <RichText className={styles.description}>{text}</RichText>
              <div className={styles.buttons}>
                <LinkButton to={left_button_link}>{left_button_label}</LinkButton>
                <LinkButton to={right_button_link}>{right_button_label}</LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Partners