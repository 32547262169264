function preventDefault(e){
  if (e.cancelable !== false)
    e.preventDefault();
}

export function disableScroll(){
  document.body.className = 'no-scroll'
  window.addEventListener('touchmove', preventDefault, { passive: false });
}
export function enableScroll(){
  document.body.className = ''
  window.removeEventListener('touchmove', preventDefault);
}