import React from "react"

export default ({range, children}) => {
  const [mediaMinWidth, mediaMaxWidth] = (range || '').replace(/ /g,'').replace(/\+$/,'').split('-')
  const maxWidthString =  !mediaMaxWidth ? '' : `and (max-width: ${mediaMaxWidth})`
  return (<>
    {!!mediaMinWidth && !!children && !!children.props && !!children.props.id &&
    <style type="text/css">{`
      #${children.props.id} {
        display: none !important;
      }
      @media(min-width: ${mediaMinWidth}) ${maxWidthString} {
        #${children.props.id} {
          display: flex !important;
        }
      }
    `}</style>}
    {children}
  </>)
}