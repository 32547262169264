import React from 'react'
import { Helmet } from "react-helmet"

export default function SEO({ lang, meta_title, meta_description, canonical_link, alternate_links, meta_tags, json_ld_schema }) {
  const htmlAttributes = !!lang ? { lang } : {}
  return (
    <Helmet htmlAttributes={htmlAttributes}>
      <title>{meta_title || "Laybuy"}</title>
      {meta_description && <meta name="description" content={meta_description} />}
      {canonical_link && canonical_link.url && <link rel="canonical" href={canonical_link.url} />}
      {
        (alternate_links || []).map(({ link, hreflang }, i) =>
          (link && link.url && <link key={i} rel="alternate" href={link.url} hreflang={hreflang} />)
        )
      }
      {
        (meta_tags || []).map(({ meta_name, meta_content, http_equiv }, i) => {
          const meta = {
            ...(meta_name && { name: meta_name }),
            ...(meta_content && { content: meta_content }),
            ...(http_equiv && { httpEquiv: meta_name })
          }
          return <meta key={i} {...meta} />
        })
      }
      {
        json_ld_schema && <script type="application/ld+json">{json_ld_schema}</script>
      }
    </Helmet>
  )
}