import React from "react"
import styles from 'src/css/slices/in_good_company.module.css'
import Image from 'src/components/image'
import { RichText } from 'prismic-reactjs'
import { Carousel } from 'src/components/react-responsive-carousel';

const InGoodCompany = ({ primary, fields }) => {
  const { heading } = primary

  const renderQuotes = () => fields.map(({ logo, description, signature }) =>
    <div className={styles.quote}>
      <div className={styles.logoCircle}>
        <Image className={styles.logo}>{logo}</Image>
      </div>
      <div className={styles.description}>
        <RichText render={description} />
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.signature}>
        <RichText render={signature} />
      </div>
    </div>
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <RichText render={heading} />
        <div className={styles.sxs}>
          {renderQuotes()}
        </div>
        <Carousel className={styles.carousel}
          showIndicators={(fields.length > 1)}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={(fields.length > 1)}
          swipeScrollTolerance={50}
          preventMovementUntilSwipeScrollTolerance={true}
        >
          {renderQuotes()}
        </Carousel>
      </div>
    </div>
  )
}

export default InGoodCompany