/**
 * This Nav Component is designed to be a stand-alone, reusable component with no dependencies
 * on other internal components (so that it can be easily dropped into other projects)
 * all files in the nav_menu folder should only user relative imports from other files in this folder
 */

import React, {useState, useRef, useEffect, useCallback, useContext} from 'react'
import styles from './nav_menu.module.css'

export const classes = (...classList) => classList.filter(c => c).join(' ')

const Logo = ({ monoChrome }) =>
  <svg
    viewBox="0 0 137 32"
    id="logo-laybuy"
    xmlns="http://www.w3.org/2000/svg"
    className={classes(styles.logo, monoChrome && styles.monoChrome)}
  >
    <path fill="#786DFF" d="M22.312 2.768l-3.416 3.558c-.245.255-.578 0-.578 0l-4.376-4.638a3.523 3.523 0 0 0-5.11 0c-1.406 1.464-1.406 3.859 0 5.323l7.258 7.56a3.523 3.523 0 0 0 5.111 0l6.074-6.326 1.065-1.138a1.814 1.814 0 0 1 2.638 0c.73.759.713 1.929-.016 2.688l-1.095 1.15-11.036 11.494c-.338.351-.665.001-.665.001L6.165 9.942a3.523 3.523 0 0 0-5.11 0c-1.406 1.464-1.406 3.859 0 5.323l14.961 15.582-.079-.072a3.523 3.523 0 0 0 5.111 0l13.93-14.508.148-.154c3.544-3.69 3.563-9.655.019-13.345-3.544-3.69-9.29-3.69-12.833 0"></path>
    <path d="M57.369 25.591h-7.987V12.19c0-.298-.245-.553-.531-.553-.286 0-.531.255-.531.553v13.87c0 .298.245.554.53.554h8.519c.266 0 .49-.235.49-.511a.507.507 0 0 0-.49-.51m6.76-4.15l3.78-8.615 3.758 8.616H64.13zm4.536-9.338c-.143-.32-.347-.532-.715-.532h-.041c-.368 0-.572.213-.715.532L61.086 25.89a.823.823 0 0 0-.082.34c0 .256.225.469.49.469.246 0 .41-.15.511-.405l1.675-3.85h8.437l1.675 3.829c.102.234.286.426.53.426a.512.512 0 0 0 .511-.511.751.751 0 0 0-.081-.34l-6.087-13.743zm19.12-.468c-.225 0-.348.149-.47.319l-5.21 7.765-5.188-7.765c-.123-.191-.266-.32-.49-.32a.57.57 0 0 0-.552.554c0 .106.041.234.102.34l5.577 8.19v5.426c0 .298.245.553.531.553.286 0 .531-.255.531-.553v-5.447l5.536-8.105c.082-.128.164-.277.164-.426a.563.563 0 0 0-.532-.531m13.379 10.488c0 1.106-.837 1.616-2.227 1.616h-3.738v-3.276h3.636c1.614 0 2.329.617 2.329 1.617v.043zm-5.965-7.531h3.105c1.328 0 2.063.553 2.063 1.531v.043c0 1.106-.878 1.574-2.267 1.574h-2.9v-3.148zm6.414 4.233c1.022-.595 1.88-1.531 1.88-3.212v-.043c0-1.02-.327-1.85-.98-2.531-.818-.851-2.105-1.32-3.74-1.32h-5.065c-.878 0-1.573.724-1.573 1.639v11.616c0 .914.695 1.638 1.573 1.638h5.23c3.227 0 5.351-1.362 5.351-4.085v-.043c0-2-1.021-3-2.676-3.659zm16.954-7.233c-.878 0-1.573.723-1.573 1.638v7.02c0 2.362-1.164 3.575-3.084 3.575-1.92 0-3.085-1.255-3.085-3.68V13.23c0-.915-.694-1.638-1.573-1.638-.878 0-1.573.723-1.573 1.638v7c0 4.382 2.35 6.616 6.19 6.616 3.84 0 6.27-2.213 6.27-6.723V13.23c0-.915-.694-1.638-1.572-1.638m16.178-.021c-.654 0-1.124.383-1.512.979l-3.534 5.254-3.473-5.212c-.388-.596-.837-1-1.552-1-.879 0-1.573.702-1.573 1.575 0 .467.164.85.388 1.17l4.617 6.53v4.234c0 .915.694 1.638 1.572 1.638.88 0 1.574-.723 1.574-1.638v-4.297l4.616-6.489c.204-.298.409-.702.409-1.148 0-.894-.572-1.596-1.532-1.596"></path>
  </svg>

const Hamburger = ({ active }) =>
  <span className={styles.hamburgerBox}>
    <span className={classes(styles.hamburgerInner, active && styles.active)} />
  </span>

const Arrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 24 24"  fill="currentColor" {...props}>
    <rect fill="none" height="24" width="24"/>
    <path d="M14.29,5.71L14.29,5.71c-0.39,0.39-0.39,1.02,0,1.41L18.17,11H3c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h15.18l-3.88,3.88 c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0l5.59-5.59c0.39-0.39,0.39-1.02,0-1.41L15.7,5.71 C15.32,5.32,14.68,5.32,14.29,5.71z"/>
  </svg>
)

const NavContext = React.createContext({})

export const NavItem = (props) => {
  const {className, button, label, component, children, arrow, ...other} = props
  const {variant, onOpenNav, navOpen} = useContext(NavContext)
  const myRef = useRef()

  const [expanded, setExpanded] = useState(false)
  const handleClick = useCallback((event) => {
    if (children) {
      onOpenNav(true, children)
      setExpanded(expanded => !expanded)
    }
  }, [children, onOpenNav])

  useEffect(() => {
    if (expanded) {
      const handleClickAway = (event) => {
        if (!window.matchMedia('(max-width: 1025px)').matches) {
          setExpanded(false)
          if (!event.target.classList.contains(styles.navItem) || event.target === myRef.current.firstChild) {
            onOpenNav(false)
          }
        }
      }
      window.addEventListener('click', handleClickAway)
      return () => {
        window.removeEventListener('click', handleClickAway)
      }
    }
  }, [onOpenNav, expanded])

  useEffect(() => {
    if (!navOpen && expanded) {
      setExpanded(false)
    }
  }, [navOpen, expanded])

  const Component = component || (children ? 'button' : 'a')
  return (
    <div className={classes(styles.navItemWrapper, expanded && styles.expanded)} ref={myRef}>
      <Component
        className={classes(className, styles.navItem, button && styles.button, variant)}
        onClick={handleClick}
        {...other}
      >
        {label}
        {arrow && (
          <span className={styles.arrow}>{<Arrow />}</span>
        )}
      </Component>
      {children && (
        <ul className={styles.submenu}>
          {children}
        </ul>
      )}
    </div>
  )
}

const NavMenu = ({
  className,
  transparent=false,
  sticky=true,
  siteBanner,
  logoLink,
  login,
  signup,
  children,
  mobileMenuFooter
}) => {
  const [navOpen, setNavOpen] = useState(false)
  const [navContent, setNavContent] = useState(null)
  const [atTop, setAtTop] = useState(true)
  const navRef = useRef()
  const bannerRef = useRef()

  const handleScroll = useCallback(() => {
    if(!navRef.current) return
    const scrollY = window.scrollY || window.pageYOffset
    const navHeight = navRef.current.offsetHeight

    if (atTop && scrollY > navHeight) {
      setAtTop(false)
    }
    else if (!atTop && scrollY <= 25) {
      setAtTop(true)
    }
  }, [atTop])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    const handleResizeBanner = () => {
      bannerRef.current.style.maxHeight = `${bannerRef.current.firstChild?.offsetHeight}px` || 0
    }
    handleResizeBanner()
    window.addEventListener('resize', handleResizeBanner)
    return () => {
      window.removeEventListener('resize', handleResizeBanner)
    }
  }, [])

  const handleOpenNav = useCallback((open, content) => {
    setNavOpen(open)
    content !== undefined && setNavContent(content)
  }, [])

  return (
    <>
      <div className={classes(styles.siteBanner, navOpen && styles.navOpen)} ref={bannerRef}>
        {siteBanner}
      </div>
      <NavContext.Provider
        value={{
          variant: (navOpen && 'navOpen') || (transparent && atTop && 'transparent') || undefined,
          onOpenNav: handleOpenNav,
          navOpen: navOpen
        }}
      >
        <div className={classes(styles.navWrapper, sticky && styles.sticky)}>
          <nav
            ref={navRef}
            className={classes(className, styles.navMenu, transparent && styles.transparent, atTop && styles.atTop, navOpen && styles.navOpen)}
          >
            <div className={styles.navContent}>
              {/*
            The logo
            */}
              <div className={styles.logoContainer}>
                {React.cloneElement(logoLink || React.Fragment, {}, <Logo monoChrome={!navOpen && transparent && atTop}/>)}
              </div>

              {/*
            The Desktop Menu
            */}
              <div className={styles.desktopMenu}>
                {children}
                <div className={styles.spacer} />
              </div>


              {/*
            The login buttons
            */}
              <section className={styles.authNav}>
                {login}
                {signup}

                {/*
              The Mobile Menu Icon
              */}
                <NavItem
                  className={styles.mobileMenuButton}
                  component="button"
                  label={<Hamburger active={navOpen} />}
                  onClick={() => handleOpenNav(!navOpen, navOpen ? undefined : null)}
                />
              </section>
            </div>
          </nav>
        </div>

      </NavContext.Provider>
      {/*
      The Mobile Menu
      */}
      <NavContext.Provider value={{variant: 'mobile'}}>
        <nav className={classes(styles.menu, styles.mobileMenu, navOpen && styles.navOpen)}>
          <ul className={styles.mobileItems}>
            {navContent || children}
          </ul>
          <div className={styles.mobileMenuFooter}>
            {mobileMenuFooter}
          </div>
        </nav>
      </NavContext.Provider>
    </>
  )
}

export default NavMenu