import React, {Component} from 'react'
import styles from 'src/css/carousel.module.css'
import {disableScroll, enableScroll} from 'src/utilities/scrolling'
import {classes} from 'src/utilities/classes'

class Carousel extends Component {
  state = {position: 0}

  componentDidMount() {
    this.setPosition()
  }

  componentDidUpdate = () => {
    this.setPosition()
  }

  componentWillUnmount() {
    enableScroll()
  }

  setPosition = () => {
    this.inner.style.transform = `translateX(${this.currentOffset}px)`
  }

  get position() {
    return this.props.position || this.state.position
  }
  set position(position) {
    this.props.onPositionChange ? this.props.onPositionChange(position) : this.setState({position})
  }

  get itemCount() {
    return this.props.itemCount
  }
  get padding() {
    const {paddingLeft, paddingRight} = getComputedStyle(this.inner)
    return parseFloat(paddingLeft) + parseFloat(paddingRight)
  }
  get itemWidth() {
    return this.inner ? ((this.inner.clientWidth + this.padding / 2) / this.props.itemCount) : 0
  }
  get positionOffset() {
    let result = -Math.min(this.itemWidth * this.position, this.maxPositionOffset)
    if (this.position === this.itemCount - 1)
      result += this.padding
    else if (this.position > 0)
      result += this.padding / 2
    return result
  }
  get maxPositionOffset() {
    return this.inner.clientWidth - this.root.clientWidth + this.padding
  }
  get currentOffset() {
    return (this._offset || 0) + this.positionOffset
  }

  handleDragStart = e => {
    if (this.root.clientWidth < this.inner.clientWidth)  {
      this._dragStart = e.clientX || e.touches[0].clientX
      this._scrollStart = window.scrollY
      this.inner.style.transition = 'none'
    }
  }

  handleDrag = e => {
    if (this._dragStart && this._scrollStart === window.scrollY) {
      this._offset = (e.clientX || e.touches[0].clientX) - this._dragStart
      if (Math.abs(this._offset) >= 10) {
        disableScroll()
      }
      if (Math.abs(this._offset) <= 10)
        this._offset = 0

      this.setPosition()
    }
  }

  handleDragEnd = e => {
    enableScroll()
    this._dragStart = undefined
    this.inner.style.transition = ''

    const change = (Math.ceil(Math.abs(this._offset) / this.itemWidth - 0.125) * -Math.sign(this._offset)) || 0
    this._offset = undefined
    this.position = Math.max(Math.min(this.position + change, this.itemCount - 1), 0)
  }

  render = () => {
    const {children, className, rootProps, itemCount, onPositionChange, ...props} = this.props
    return (
      <div ref={ref => this.root = ref} {...rootProps}
           className={classes(rootProps && rootProps.className, styles.root)}
           onMouseDown={this.handleDragStart} onTouchStart={this.handleDragStart}
           onMouseMove={this.handleDrag} onTouchMove={this.handleDrag}
           onMouseUp={this.handleDragEnd} onTouchEnd={this.handleDragEnd}
           onMouseLeave={this.handleDragEnd}>
        <div ref={ref => this.inner = ref} className={classes(className, styles.inner)} {...props}>
          {children}
        </div>
      </div>
    )
  }
}
export default Carousel