import React from "react"
import AdaptiveImage from 'src/components/adaptive_image'
import LinkButton from 'src/components/link_button'
import { RichText } from 'prismic-reactjs'
import styles from 'src/css/slices/become_a_partner.module.css'
import { Carousel } from 'src/components/react-responsive-carousel'
import { classes } from 'src/utilities/classes'

const BecomeAPartner = ({ primary, fields }) => {
  const { heading, description, button_link, button_text, interval, auto_play, swipeable, swipe_tolerance } = primary

  const carouselInterval = (interval || 5000)
  const carouselAutoPlay = (auto_play == null) ? true : auto_play
  const carouselSwipeable = (swipeable == null) ? true : swipeable
  const carouselSwipeTolerance = (swipe_tolerance || 50)

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <RichText render={heading} />
          <RichText render={description} />
          <LinkButton to={button_link} className={styles.button}>
            {button_text}
          </LinkButton>
        </div>
        <div className={classes(styles.right, "rrc-carousel-dots")}>
          <Carousel
            showIndicators={true}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={carouselAutoPlay}
            swipeable={carouselSwipeable}
            interval={carouselInterval}
            swipeScrollTolerance={carouselSwipeTolerance}
            preventMovementUntilSwipeScrollTolerance={true}
          >
            {fields.map(({ desktop_image, mobile_image }, i) =>
              <AdaptiveImage desktop={desktop_image} mobile={mobile_image} key={i} />
            )}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default BecomeAPartner