import React, { Component } from 'react'
import styles from 'src/css/slices/benefits.module.css'
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import DynamicStyle from 'src/components/dynamic_style'

class Benefits extends Component {
  render = () => {
    const { fields, primary, sliceIndex } = this.props
    const { feature_image, heading, heading_color } = primary
    return (
      <div className={styles.wrapper}>
        <div className={styles.benefits}>
          <div className={styles.inner}>
            <RichText className={styles.heading} style={{ color: heading_color }}>{heading}</RichText>
            <div className={styles.featureImage}>
              <Image dpiRatio={2}>{feature_image}</Image>
            </div>
            <div className={styles.content}>
              <ul>
                {fields.map(({ benefit, benefit_heading_color, benefit_color, benefit_icon }, i) =>
                  <DynamicStyle dynamicStyle={{
                    '::before': { backgroundImage: benefit_icon ? `url(${benefit_icon.url})` : undefined },
                    color: benefit_color,
                    '& p': { color: benefit_color },
                    ...([1, 2, 3, 4, 5, 6].reduce((o, x) => ({ ...o, [`& h${x}`]: { color: benefit_heading_color } }), {}))
                  }}>
                    <li key={i} id={`slice-${sliceIndex}-benefit-${i}`}>
                      <RichText>{benefit}</RichText>
                    </li>
                  </DynamicStyle>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Benefits