import React, {Component} from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import Button from 'src/components/button'
import Link from 'src/components/link'
import styles from 'src/css/slices/merchats.module.css'
import Carousel from 'src/components/carousel'
import {classes} from 'src/utilities/classes'
import Masonry from 'react-masonry-component'
import DynamicStyle from 'src/components/dynamic_style'

class Merchants extends Component {
  masonryOptions = {
    columnWidth: 10,
    gutter: 10,
    originLeft: false
  }

  render = () => {
    const {primary, fields, merchantLogos} = this.props
    const {
      buyer_button_label, buyer_button_link, new_merchants_image, new_merchants_text, new_merchants_text_color,
      seller_button_label, seller_button_link, background_color, wave_color,
      left_button_text_color, left_button_color, right_button_text_color, right_button_color
    } = primary
    const logos = merchantLogos.map(l => ({...l, position: Math.random()})).sort((a,b) => a-b)
    const backgroundColor = (background_color || '#786dff');
    const waveColor = (wave_color || '#533187');
    return (
      <div className={classes(styles.merchants,"merchants-slice-wrapper")}>
        <style type="text/css">
          {`
            .merchants-slice-wrapper {
              background-color: ${waveColor};
            }
            .merchants-slice-just-added {
              background: ${backgroundColor};
            }
            .merchants-slice-just-added::before {
              /* Inline version of /images/overlay-purple.svg - color is interpolated */
              background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221463%22%20height%3D%22354%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cpath%20id%3D%22overlay-purple-a%22%20d%3D%22M0%200h1463v720H0z%22%2F%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20transform%3D%22translate%280%20-366%29%22%3E%0A%20%20%20%20%3Cmask%20id%3D%22overlay-purple-b%22%20fill%3D%22%23fff%22%3E%0A%20%20%20%20%20%20%3Cuse%20xlink%3Ahref%3D%22%23overlay-purple-a%22%2F%3E%0A%20%20%20%20%3C%2Fmask%3E%0A%20%20%20%20%3Cpath%20fill%3D%22${waveColor.replace('#','%23')}%22%20d%3D%22M.139%20366c57.926%20116.797%20146.868%20167.294%20266.826%20151.492%20166.083-26.396%20275.942%204.145%20329.576%2091.624%2080.452%20131.219%20188.25%20101.26%20203.272%20101.26%2015.021%200%20185.3-79.914%20400.18-13.631%20101.524%2031.316%20189.193%2024.433%20263.007-20.65V721H0V366h.139z%22%20mask%3D%22url%28%23overlay-purple-b%29%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E") 50% 100% no-repeat;
              background-size: 100%;
            }
          `}
        </style>
        <div className={classes(styles.justAdded, "merchants-slice-just-added")}>
          <Carousel itemCount={fields.length} className={styles.carousel}>
            {fields.map(({merchant_image, merchant_button_label, merchant_link, merchant_text, merchant_text_color, button_color, button_text_color}, i) =>
              <div className={styles.shopCard} key={i}>
                <Image>{merchant_image}</Image>
                <div className={styles.shopCardBody}>
                  <RichText className={styles.shopCardText} style={{ color: merchant_text_color }}>{merchant_text}</RichText>
                  <Link to={merchant_link}>
                    <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: button_text_color }, background: button_color }}>
                      <Button id={`merchants-slice-button-${i}`} withArrow>{merchant_button_label}</Button>
                    </DynamicStyle>
                  </Link>
                </div>
              </div>
            )}
          </Carousel>
        </div>
        <div className={styles.infoSection}>
          <div className={styles.infoWrapper}>
            <div className={styles.info}>
              <span style={{display: 'inline-block'}}>
                <Image>{new_merchants_image}</Image>
              </span>
              <RichText className={styles.infoText} style={{color: new_merchants_text_color}}>{new_merchants_text}</RichText>
              <Link to={buyer_button_link}>
                <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: left_button_text_color }, background: left_button_color }}>
                  <Button id={`merchants-slice-button-left`} inverted>{buyer_button_label}</Button>
                </DynamicStyle>
              </Link>
              <Link to={seller_button_link}>
                <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: right_button_text_color }, background: right_button_color }}>
                  <Button id={`merchants-slice-button-right`}>{seller_button_label}</Button>
                </DynamicStyle>
              </Link>
            </div>

          </div>
          <div className={styles.merchantLogoWrapper}>
            <Masonry id="masonry-layout" options={this.masonryOptions} className={styles.logoMasonry}>
              {logos.map(({image}, idx) =>
                <div key={idx} className={classes(styles.merchantLogo, Math.random() < 0.5 && styles.small)}>
                  <Image>{image}</Image>
                </div>
              )}
            </Masonry>
          </div>
        </div>
      </div>
    )
  }
}

export default Merchants