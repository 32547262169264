import React, { Component } from 'react'
import { countryNames, determineLocale, alias, switchLocale } from 'src/utilities/locale'
import styles from 'src/css/locale_switcher.module.css'
import PageContext from 'src/components/page_context'

export class LocaleSwitcher extends Component{
  state = {}

  componentDidMount = async () => {
    this.setState({userLang: await determineLocale(true)})
  }

  get shouldShowLanguagePrompt(){
    return !this.state.languagePromptDismissed && this.state.userLang && this.state.userLang !== this.props.pageContext.lang
  }

  get localizedPath(){
    return `/${window.location.pathname.replace(/^\/?[a-zA-Z]+(?=[/$])/,alias(this.state.userLang))}`
  }

  handleSwitchLocale = (event) => {
    event.preventDefault()
    switchLocale(this.state.userLang)
    window.location.replace(this.localizedPath)
  }

  handleDismissLanguagePrompt = () =>
    this.setState({languagePromptDismissed: true})

  render = () =>
    this.shouldShowLanguagePrompt ?
    <div className={styles.alert}>
      <div className={styles.alertContainer}>
        <div className={styles.alertContainerInner}>
          <p className={styles.alertContainerMessage}>It looks like you’re in <strong>{countryNames[this.state.userLang]}</strong>. Should we take you to the {countryNames[this.state.userLang]} site?</p>
          <p className={styles.alertContainerAction}>
            <a href={this.localizedPath} onClick={this.handleSwitchLocale} className={styles.alertButton}>Yes, take me there</a>
            <button onClick={this.handleDismissLanguagePrompt} className={styles.alertClose}>No, thanks</button>
          </p>
        </div>
      </div>
      <button onClick={this.handleDismissLanguagePrompt} className={styles.closeButton} type="button" aria-label="Close" title="Close">
        <svg className="s-icon s-icon--close">
          <path d="M7 5.027L11.606.42a1.392 1.392 0 0 1 1.969 0l.004.004a1.392 1.392 0 0 1 0 1.97L8.973 7l4.606 4.606a1.392 1.392 0 0 1 0 1.969l-.004.004a1.392 1.392 0 0 1-1.97 0L7 8.973 2.394 13.58a1.392 1.392 0 0 1-1.969 0l-.004-.004a1.392 1.392 0 0 1 0-1.97L5.027 7 .42 2.394a1.392 1.392 0 0 1 0-1.969L.425.421a1.392 1.392 0 0 1 1.97 0L7 5.027z" fill="white"></path>
        </svg>
      </button>
    </div>:
    false
}

export default () =>
  <PageContext.Consumer>
    {pageContext => <LocaleSwitcher pageContext={pageContext}/>}
  </PageContext.Consumer>