import React from 'react'
import {RichText as BaseRichText} from 'prismic-reactjs'
import {linkResolver} from 'src/utilities/linkResolver'
import styles from 'src/css/rich_text.module.css'
import {classes} from 'src/utilities/classes'

const RichText = ({children, className, ...props}) => (
  <div className={classes(className, styles.root)} {...props}>
    <BaseRichText render={children} linkResolver={linkResolver}/>
  </div>
)
export default RichText