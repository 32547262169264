import React from "react"
import * as slices from 'src/slices'
import {camelize} from 'src/utilities/string'

const Slices = ({data, ...props}) =>
  <>
    {
      (data || []).map(({type, ...data}, idx) =>
        {
          const Slice = slices[camelize(type, true)]
          return Slice && (data?.primary?.enabled === false ? null : <Slice key={idx} sliceIndex={idx} {...data} {...props}/>)
        }
      ).filter(s => s)
    }
  </>

export default Slices