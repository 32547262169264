import React, {Component} from 'react'
import styles from 'src/css/carousel_dots.module.css'
import {classes} from 'src/utilities/classes'

class CarouselDots extends Component {
  get items() {
    const result = []
    const {count} = this.props
    for (let i = 0; i < count; i++) {
      result.push(i)
    }
    return result
  }

  selected = i => {
    return this.props.index === i
  }

  handleSet = i => () => this.props.onIndexChange(i)

  render = () => {
    const {className, count, index, onIndexChange, ...props} = this.props
    return (
      <ul className={classes(styles.carouselDots, this.props.className)} {...props}>
        {this.items.map(i =>
          <li key={i}>
            <button onClick={this.handleSet(i)} className={classes(this.selected(i) && styles.active)}/>
          </li>
        )}
      </ul>
    )
  }
}
export default CarouselDots