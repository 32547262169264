import { graphql } from "gatsby"
export const Homepage = graphql`
  fragment Homepage on PRISMIC_Homepage_15ConnectionConnection {
    edges {
      node {
        _meta {
          ...Meta
        }
        ...HomePageSEO
        body {
          ... on PRISMIC_Homepage_15BodyHero_carousel {
            type
            label
            primary {
              enabled
              header_text
              left_button_text
              left_button_link { ...Link }
              right_button_text
              right_button_link { ...Link }
              left_mobile_button_text
              left_mobile_button_link { ...Link }
              right_mobile_button_text
              right_mobile_button_link { ...Link }
              hero_image_link { ...Link }
              auto_play
              interval
              swipeable
              swipe_scroll_tolerance
            }
            fields {
              background_image
              mobile_image
              background_color
              footer_gradient_color
              stretch_mobile_image
              stretch_desktop_image
              slide_header_text
              slide_left_button_text
              slide_left_button_link { ...Link }
              slide_right_button_text
              slide_right_button_link { ...Link }
              slide_hero_image_link { ...Link }
            }
          }
          ... on PRISMIC_Homepage_15BodyPhone_hero_carousel {
            type
            label
            primary {
              enabled
              header_text
              left_button_text
              left_button_link { ...Link }
              right_button_text
              right_button_link { ...Link }
              left_mobile_button_text
              left_mobile_button_link { ...Link }
              right_mobile_button_text
              right_mobile_button_link { ...Link }
              auto_play
              interval
              swipeable
              swipe_scroll_tolerance
              phone_label
            }
            fields {
              background_image
              mobile_background_image
              phone_image
              logo
              background_color
            }
          }
          ... on PRISMIC_Homepage_15BodyFeatures {
            type
            label
            primary {
              enabled
            }
            fields {
              header_text
              background_image
              background_color
              button_text
              button_link { ...Link }
            }
          }
          ... on PRISMIC_Homepage_15BodyFour_features {
            type
            label
            primary {
              enabled
            }
            fields {
              icon
              title
              description
            }
          }
          ... on PRISMIC_Homepage_15BodyTiles {
            type
            label
            primary {
              tile_hover_color_1
              tile_hover_color_2
              tile_hover_color_3
            }
            fields {
              heading: header_text
              badge_text
              disclaimer
              category_id
              order
              max_tiles
            }
          }
          ... on PRISMIC_Homepage_15BodyNoteworthy {
            type
            label
            primary {
              heading: header_text
              background_color
            }
            fields {
              image
              text
              text_color
              button_text
              button_link { ...Link }
              button_color
              button_text_color
              badge_text
            }
          }
          ... on PRISMIC_Homepage_15BodyBrands {
            type
            label
            primary {
              header_text
              text
              desktop_image
              mobile_image
              background_color
              left_button_text
              left_button_link { ...Link }
              right_button_text
              right_button_link { ...Link }
            }
          }
          ... on PRISMIC_Homepage_15BodyInfo_carousel {
            type
            label
            fields {
              header_text
              text
              button_text
              button_link { ...Link }
              button_image
              background_color
              text_color
              desktop_image
              mobile_image
              control_dot_color
              control_dot_selected_color
            }
          }
          ... on PRISMIC_Homepage_15BodyFull_width_promo {
            type
            label
            primary {
              promo_image
              promo_image_mobile
              promo_url {
                ...Link
              }
            }
          }
          ... on PRISMIC_Homepage_15BodyMobile_payment {
            type
            label
            primary {
              feature_image
              feature_image_mobile
              header_text: heading
              logo
              button_text
              button_link { ...Link }
              fine_print
              background_color
            }
          }
        }
      }
    }
  }
`