export const camelize = (string, uppercase = false) => {
  return words(string).map((s, i) =>
    ((i > 0 || uppercase) ? s[0].toUpperCase() : s[0].toLowerCase()) + s.slice(1)
  ).join('')
}

export const words = string => {
  return `${string}`.split(/(?=[A-Z])|_|-/)
}

export const kebabize = (string) => {
  return string.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

export const generateHtmlId = (base) => {
  return `${base}-` + Math.random().toString(36).substr(2, 9)
}

// Rich text fields can be empty in prismic but still return a data structure
export const richTextEmpty = (richText) => {
  if (richText && richText.length === 1 && richText[0] && !richText[0].text) {
    return true;
  }
  return !richText;
}

export const coalesceRichText = (...args) => {
  return args.filter(rt => !richTextEmpty(rt))[0]
}