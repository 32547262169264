import React, {Component} from "react"
import ImageWithTextFloating from './image_with_text_floating'
import ImageWithTextFixed from './image_with_text_fixed'

class ImageWithText extends Component {
  render = () => {
    const { floating_image } = this.props.primary
    const Component = floating_image ? ImageWithTextFloating : ImageWithTextFixed
    return <Component {...this.props} />
  }
}

export default ImageWithText