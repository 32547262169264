import qs from 'qs'

const baseUrl = process.env.GATSBY_SHOP_DIRECTORY_API_URL

export const fetchData = async(path, params) => {
  const url = buildUrl(path, params)
  const response = await fetch(url)
  const json = await response.json()
  return decodeJSONApiResponse(json)
}

const buildUrl = (path, params) => {
  const location = `${baseUrl}/${path}`
  const query = qs.stringify(params, {arrayFormat: 'brackets'})
  return [location, query].filter(p => p).join('?')
}

export function decodeJSONApiResponse(response, options = {}) {
  let { method } = options
  let { meta } = response
  if(method === 'delete') {
    // see http://jsonapi.org/format/#crud-deleting
    return { meta }
  }

  let { data: result, included } = response
  included = (included || []).concat(flatten([result]))
  if (result === null || result === undefined) {
    if (meta === null || meta === undefined) {
      throw new Error('result should not be null or undefined')
    } else {
      return { meta, ...(included ? {included} : {}) }
    }
  }

  if (Array.isArray(result)) {
    return {data: result.map(r => decodeJSONApiResponse({data: r, included}).data), meta}
  } else {
    const { id, type, attributes, relationships } = result || {}
    const decoded = {
      data: {
        id, type, ...attributes
      }, meta
    }
    if(relationships){
      fillRelationships(decoded, relationships, buildIncludedMap(included))
    }
    return decoded
  }
}

function flatten(collection){
  return collection.reduce(function(prev, inner) {
    inner = Array.isArray(inner) ? inner : [inner]
    const  multiLevel = (inner).some(Array.isArray);
    return prev.concat(multiLevel ? flatten(inner) : inner);
  },[]);
}

function buildIncludedMap(included){
  const includedMap = {}
  included = included || []
  included.forEach(included => {
    includedMap[`${included.id}.${included.type}`] = {id: included.id, type: included.type, ...included.attributes }
  })
  included.forEach(({id, type, relationships}) => {
    if(relationships && Object.entries(relationships).length){
      const object = includedMap[`${id}.${type}`]
      Object.entries(relationships).forEach(([relationship, {data}]) => {
        if(!data)
          return
        const extractObject = ({id, type}) => includedMap[`${id}.${type}`]
        object[relationship] = Array.isArray(data) ? data.map(extractObject) : extractObject(data)
      })
    }
  })
  return includedMap
}

function fillRelationships(decoded, relationships, included){
  Object.entries(relationships).forEach(([relationship, {data}]) => {
    const extractObject = ({id, type, relationships}) => ({id, type, ...included[`${id}.${type}`]})
    if(data) {
      decoded.data[relationship] = Array.isArray(data) ? data.map(extractObject) : extractObject(data)
    }
  })
  return decoded
}

