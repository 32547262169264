import { graphql } from "gatsby"
export const LandingPage = graphql`
  fragment LandingPage on PRISMIC_Landing_pageConnectionConnection {
    edges {
      node {
        _meta {
          ...Meta
        }
        show_instagram
        ...LandingPageSEO
        body {
          ... on PRISMIC_Landing_pageBodyFull_width_promo {
            type
            label
            primary {
              promo_image
              promo_image_mobile
              promo_url {
                ...Link
              }
              media_width_range
            }
          }
          ... on PRISMIC_Landing_pageBodyButton_banner {
            type
            label
            primary {
              button_color
              button_label
              button_link {
                ...Link
              }
              button_text_color
              extra_info
              gradient_color_end
              gradient_color_start
              heading
            }
          }
          ... on PRISMIC_Landing_pageBodyVideo {
            type
            primary {
              video {
                ...Link
              }
              poster
            }
          }
          ... on PRISMIC_Landing_pageBodyIntroduction {
            type
            fields {
              feature_image
              feature_text
            }
            primary {
              headline
              intro_button_label
              intro_button_link {
                ...Link
              }
              intro_button_color
              intro_button_text_color
            }
          }
          ... on PRISMIC_Landing_pageBodyBenefits {
            type
            fields {
              benefit
            }
            primary {
              feature_image
              heading
            }
          }
          ... on PRISMIC_Landing_pageBodyHow_it_works {
            type
            fields {
              item_image
              item_text
            }
            primary {
              feature_image
              heading
            }
          }
          ... on PRISMIC_Landing_pageBodyImage_with_text {
            type
            primary {
              button_label
              button_link {
                ...Link
              }
              button_color
              button_text_color
              image_position_right
              floating_image
              feature_image
              feature_image_mobile
              text
              text_color
              gradient_color_start
              gradient_color_end
              full_width
            }
          }
          ... on PRISMIC_Landing_pageBodyRaw_html {
            type
            primary {
              raw_html
            }
          }
          ... on PRISMIC_Landing_pageBodySpacer {
            type
            primary {
              height
              unit
            }
          }
          ... on PRISMIC_Landing_pageBodyContent_area {
            type
            label
            fields {
              type: type
              text: text
              richText: rich_text
              padding: padding
              width: width
              maxWidth: max_width
              lineHeight: line_height
              image: image
              foregroundColor: foreground_color
              fontSize: font_size
              backgroundColor: background_color
              linkColor: link_color
              link {
                ...Link
              }
            }
            primary {
              backgroundImage: background_image
              backgroundGradient: background_gradient
              backgroundColor: background_color
              backgroundPosition: background_position
              backgroundSize: background_size
              minHeight: min_height
              padding: padding
              width: width
              textAlign: text_align
              verticalAlign: vertical_align
              mediaWidthRange: media_width_range
            }
          }
          ... on PRISMIC_Landing_pageBodyContent_area_wrapper {
            type
            label
            primary {
              background_image
              background_gradient
              background_color
              background_position
              background_size
              min_height
              padding
              full_width
              media_width_range
            }
          }
        }
      }
    }
  }
`