import React from 'react'
import { ReactSVG } from 'react-svg'

const Image = ({children, dpiRatio = 1, inlineSVG, objectFit, loading, ...props}) => {
  const {url, alt, dimensions: {width, height} = {}} = (children || {})
  if (objectFit && url && typeof(document) !== 'undefined' && !('objectFit' in document.documentElement.style)) {
    return <div style={{ backgroundImage: `url("${url}")`, backgroundSize: objectFit, backgroundRepeat: 'no-repeat'}} {...props} />
  } else {
    return (
      (inlineSVG && url.toUpperCase().includes(".SVG")) ?
        <ReactSVG src={url} alt={alt} width={width && width / dpiRatio} height={height && height / dpiRatio} {...props} /> :
        <img src={url} alt={alt} width={width && width / dpiRatio} height={height && height / dpiRatio} loading={loading} {...props}/>
    )
  }
}
export default Image