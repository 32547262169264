import React from 'react'
import styles from '../styles/header.module.css'
import Search from '../images/search'
import Times from '../images/times'
import {classes} from '../utils/classes'

const Header = ({filter, headerText, headerTextColor, placeholderText, onFilterChange, onSearchTextChange, onClearSearch, backgroundImage, hideOnlineInStoreToggles}) =>
  <div className={classes(styles.wrapper, hideOnlineInStoreToggles && styles.extraPadding)} style={backgroundImage && backgroundImage.url ? {backgroundImage: `url(${backgroundImage.url})`} : {}}>
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 style={{ color: headerTextColor }}>{headerText || 'Search, shop, repeat...'}</h1>
        <div className={styles.search}>
          <input type='search' placeholder={placeholderText} value={filter.searchText || ''} onChange={onSearchTextChange}/>
          <div className={styles.searchButton}>
            {!!filter.searchText && <button onClick={onClearSearch}><Times/></button>}
            <button><Search/></button>
          </div>
        </div>
        {
          typeof window !== 'undefined' && !hideOnlineInStoreToggles &&
          <div className={styles.options}>
            <button className={classes(!filter.online && !filter.inStore && styles.active)}
                    onClick={() => onFilterChange({online: undefined, inStore: undefined})}>
              All
            </button>
            <button className={classes(filter.online && styles.active)}
                    onClick={() => onFilterChange({online: true, inStore: undefined})}>
              Online
            </button>
            <button className={classes(filter.inStore && styles.active)}
                    onClick={() => onFilterChange({online: undefined, inStore: true})}>
              In Store
            </button>
          </div>
         }
      </div>
    </div>
  </div>
export default Header
