import React from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import styles from 'src/css/slices/introduction.module.css'
import Button from 'src/components/button'
import Link from 'src/components/link'
import DynamicStyle from 'src/components/dynamic_style'

const Introduction = ({sliceIndex, primary: {headline, headline_color, intro_button_label, intro_button_link, intro_button_text_color, intro_button_color}, fields}) => {
  return (
    <div className={styles.intro} id='intro'>
      <RichText style={{ color: headline_color }}>{headline}</RichText>
      <div className={styles.features}>
        {
          fields.map(({feature_image, feature_text, feature_text_color}, idx) =>
            <div key={idx} className={styles.feature}>
              <div className={styles.featureImageWrapper}>
                <span style={{display: 'inline-block'}}>
                  <Image className={styles.featureImage}>{feature_image}</Image>
                </span>
              </div>
              <RichText style={{ color: feature_text_color }}>{feature_text}</RichText>
            </div>
          )
        }
      </div>
      <Link to={intro_button_link}>
        <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: intro_button_text_color }, background: intro_button_color }}>
          <Button id={`introduction-button-${sliceIndex}`} className={styles.introButton} withArrow>{intro_button_label}</Button>
        </DynamicStyle>
      </Link>
    </div>
  )
}

export default Introduction