import React, { useState, useRef } from 'react'
import CloseModal from 'src/images/close_modal'
import Button from 'src/components/button'
import Link from 'src/components/link'
import { RichText } from 'prismic-reactjs'
import styles from '../styles/tile_modal.module.css'
import tileStyles from '../styles/tile.module.css'

export const TapToPayModalContent = ({ onCloseModal, siteSettings }) => {
  const videoRef = useRef(null)
  const [videoPlaying, setVideoPlaying] = useState(false)

  let {
    tap_to_pay_popup_image,
    tap_to_pay_header_text,
    tap_to_pay_description,
    tap_to_pay_browse_button_text,
    tap_to_pay_browse_link,
    tap_to_pay_view_button_text,
    tap_to_pay_video_link,
  } = (siteSettings || {})

  const imageUrl = (tap_to_pay_popup_image && tap_to_pay_popup_image.url) || "/images/tap_to_pay.png"
  const tileImage =
    <div className={styles.tileImages}>
      <div className={styles.tileImage}>
        <img src={imageUrl} alt='Tap To Pay' />
      </div>
    </div>

  const startVideo = () => {
    setVideoPlaying(true)
    videoRef.current && videoRef.current.play()
  }

  return <div className={`${styles.popup}${videoPlaying ? (' ' + styles.videoPlaying) : ''}`}>
    <div className={styles.video}>
      <button className={styles.close} onClick={onCloseModal}>
        <CloseModal />
      </button>
      <video src={tap_to_pay_video_link && tap_to_pay_video_link.url} playsInline="" preload="metadata" controls={true} ref={videoRef} onEnded={() => setVideoPlaying(false)} />
    </div>
    <div className={styles.content}>
      <div className={tileStyles.imgWrapper}>
        <button className={styles.close} onClick={onCloseModal}>
          <CloseModal />
        </button>
        {tileImage}
      </div>
      <h2 className={styles.tapToPayHeader}>
        {tap_to_pay_header_text || 'How to tap to Pay in 6.'}
      </h2>
      <div className={styles.tapToPayDescription}>
        {tap_to_pay_description ? <RichText render={tap_to_pay_description} /> : <>
          <p><strong>1. Shop at ‘tap enabled’ retailers.<br /> </strong>New stores are always being added.</p>
          <p><strong>2. Open your Laybuy Card to Pay in 6.<br /> </strong>Find it in your digital wallet.</p>
          <p><strong>3. Wave, pass or tap as normal.<br /> </strong>That’s it. Pay only 1/6th today.</p>
        </>}
      </div>
      <div className={styles.buttons}>
        <Link rel="noopener noreferrer" to={tap_to_pay_browse_link}>
          <Button inverted className={styles.inverted}>{tap_to_pay_browse_button_text || 'Browse Stores'}</Button>
        </Link>
        <Button onClick={startVideo}>{tap_to_pay_view_button_text || 'Watch Video'}</Button>
      </div>
    </div>
  </div>
}