export function optionalThreeColorGradient(color1, color2, color3) {
  const colors = [color3, color2, color1].filter(c => !!c)
  return colors.length === 3 ? `linear-gradient(-50deg,${colors[0]},${colors[1]} 45%,${colors[2]})` :
    colors.length === 2 ? `linear-gradient(-50deg,${colors[0]},${colors[1]})` :
    colors.length === 1 ? colors[0] : undefined;
}

export function optionalBackgroundGradient(colorStart, colorEnd) {
  const gradientColorStart = colorStart || colorEnd
  const gradientColorEnd = colorEnd || colorStart
  const background = (gradientColorStart === gradientColorEnd) ? gradientColorStart : `linear-gradient(50deg,${gradientColorStart},${gradientColorEnd})`
  return background ? background : undefined;
}