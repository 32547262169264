import React, { useState } from "react"
import Link from 'src/components/link'
import Button from 'src/components/button'
import Image from 'src/components/image'
import { RichText } from 'prismic-reactjs'
import styles from 'src/css/slices/featured_partners.module.css'
import ArrowLeft from 'src/images/arrow_left'
import ArrowRight from 'src/images/arrow_right'
import { classes } from 'src/utilities/classes'
import { Carousel } from 'src/components/react-responsive-carousel'

const FeaturedPartners = ({ primary, fields }) => {
  const { default_button_text, heading } = primary
  const [page, setPage] = useState(0)
  const pageSize = 3
  const pageStart = (page * pageSize)
  const pageEnd = pageStart + pageSize - 1;
  const maxPage = Math.ceil(fields.length / pageSize) - 1

  const carouselInterval = 5000
  const carouselAutoPlay = true
  const carouselSwipeable = true
  const carouselSwipeTolerance = 50

  const renderField = ({ image, description, button_text, button_link }, i) =>
    <div key={i} className={classes(styles.partner, (i < pageStart || i > pageEnd) && styles.desktopHidden)}>
      <Image className={styles.image} objectFit="contain">{image}</Image>
      <div className={styles.description}>
        <RichText render={description} />
      </div>
      <Link to={button_link} target="_blank">
        <Button withArrow style={{ backgroundColor: 'black' }} className={styles.button}>
          {button_text || default_button_text || 'Visit website'}
        </Button>
      </Link>
    </div>

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.heading}>
          <div>
            <RichText render={heading} />
          </div>
          <div className={classes(styles.nav, maxPage <= 0 && styles.hidden)}>
            <button onClick={() => setPage(page - 1)} disabled={page <= 0}><ArrowLeft /></button>
            <button onClick={() => setPage(page + 1)} disabled={page >= maxPage}><ArrowRight /></button>
          </div>
        </div>
        <Carousel
          showIndicators={true}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={carouselAutoPlay}
          swipeable={carouselSwipeable}
          interval={carouselInterval}
          swipeScrollTolerance={carouselSwipeTolerance}
          preventMovementUntilSwipeScrollTolerance={true}
          className={`${styles.mobileCarousel} rrc-carousel-dots`}
        >
          {fields.map(renderField)}
        </Carousel>
        <div className={styles.partnerContainer}>
          {fields.map(renderField)}
        </div>
      </div>
    </div>
  )
}

export default FeaturedPartners