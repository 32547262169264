import React, {Component} from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import LinkButton from 'src/components/link_button'
import styles from 'src/css/slices/dashboard.module.css'

class Dashboard extends Component {
  render = () => {
    const {background_color, feature_image, text, button_label, button_link} = this.props.primary
    return (
      <div className={styles.wrapper} style={{backgroundColor: background_color}}>
        <div className={styles.dashboard}>
          <div className={styles.inner}>
            <div className={styles.content}>
              <RichText className={styles.description}>{text}</RichText>
              {button_label && <LinkButton to={button_link}>{button_label}</LinkButton>}
            </div>
            <div className={styles.featureImage}>
              <Image dpiRatio={2}>{feature_image}</Image>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard