import React from "react"
import styles from 'src/css/slices/content_area_wrapper.module.css'
import {classes} from 'src/utilities/classes'
import Slices from 'src/components/slices'
import MediaWidthWrapper from 'src/components/media_width_wrapper'

export default ({content_areas, primary, sliceIndex}) => {
  const {
    min_height,
    background_image,
    background_gradient,
    background_position,
    background_size,
    background_color,
    padding,
    full_width,
    media_width_range
  } = (primary || {})

  const outerStyle = {
    minHeight: min_height,
    backgroundImage: [background_image ? `url(${background_image.url})` : undefined, background_gradient].filter(b => !!b).join(', '),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: background_position,
    backgroundSize: background_size || 'contain',
    backgroundColor: background_color,
    padding: padding
  }

  const outerId = `content-area-wrapper-${sliceIndex}`
  return (
    <MediaWidthWrapper range={media_width_range}>
      <div id={outerId} className={classes(styles.outer)} style={outerStyle}>
        <div className={classes(styles.inner, !full_width && styles.contained)}>
          <Slices data={content_areas} idPrefix={`caw-${sliceIndex}`} />
        </div>
      </div>
    </MediaWidthWrapper>
  )
}