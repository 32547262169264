import React, { useContext, useEffect } from 'react'
import { GlobalStateContext } from 'src/components/global_state_provider'
import Close from 'src/images/close'
import styles from 'src/css/site_banner.module.css'
import RichText from 'src/components/rich_text'

export default function SiteBanner({ siteBannerData }) {
  const { rotation_frequency_seconds: rotationFrequencySeconds, can_be_closed: canBeClosed, banners } = (siteBannerData || {})
  const { globalState: { siteBannerVisible = true, siteBannerIndex = 0, animate = true } = {}, setGlobalState } = (useContext(GlobalStateContext) || {});
  const bannerCount = (banners || []).length

  useEffect(() => {
    const rotationFrequency = ((rotationFrequencySeconds || 10) * 1000);
    const timeoutId = (siteBannerVisible && bannerCount > 1) ?
      setTimeout(() => {
        setGlobalState({ siteBannerIndex: ((siteBannerIndex + 1) % (bannerCount + 1)), animate: true})
        if(siteBannerIndex === bannerCount - 1)
          setTimeout(() => setGlobalState({ siteBannerIndex: 0, animate: false}), 1000)
      }, rotationFrequency) : undefined;
    return () => (timeoutId && clearTimeout(timeoutId));
  }, [siteBannerVisible, siteBannerIndex, setGlobalState, bannerCount, rotationFrequencySeconds])

  const currentBanner = (banners && banners.length >= siteBannerIndex && banners[siteBannerIndex]) || {}
  const trackStyle = {width: `${(bannerCount+1) * 100}vw`, marginLeft: `-${siteBannerIndex * 100}vw`}
  const classes = `${styles.siteBannerTrack} ${animate ? '' : styles.noanimate}`
  return (
    (siteBannerVisible && bannerCount > 0) ?
      <div key='viewport' className={styles.siteBannerViewport}>
        <div className={classes} style={trackStyle}>
          {(banners || []).concat(banners[0]).map(({ text_color, background_color, message }, idx) =>
            <RichText className={styles.bannerText} key={idx} style={{backgroundColor: background_color, color: text_color}}>{message}</RichText>
          )}
          {canBeClosed && <button type="button" aria-label="Close" title="Close" className={styles.siteBannerClose} style={{color: currentBanner.text_color}}
            onClick={() => { setGlobalState({ siteBannerVisible: false }) }}>
            <Close />
          </button>}
        </div>
      </div>:
      false
  )
}