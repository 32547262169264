import React from 'react'
import ShopDirectory from 'src/shop_directory'
import PageContext from 'src/components/page_context'
import SiteSettingsContext from 'src/components/site_settings_context'

export default ({ primary, pageData }) => {
  const orderTypesInTheCategoryMenu = (pageData && pageData.order_types_in_the_category_menu) || []
  return (
    <SiteSettingsContext.Consumer>
      {siteSettings =>
        <PageContext.Consumer>
          {
            pageContext => <ShopDirectory siteSettings={siteSettings} locale={pageContext.lang} {...primary} orderTypesInTheCategoryMenu={orderTypesInTheCategoryMenu} />
          }
        </PageContext.Consumer>
      }
    </SiteSettingsContext.Consumer>
  )
}