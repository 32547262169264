import React from "react"
import styles from 'src/css/slices/brands.module.css'
import LinkButton from 'src/components/link_button'
import { RichText } from 'prismic-reactjs'

const Brands = ({ primary }) => {
  const {
    header_text,
    text,
    desktop_image: { url: desktopImageUrl, alt: desktopImageAlt } = {},
    mobile_image: { url: mobileImageUrl, alt: mobileImageAlt } = {},
    background_color: backgroundColor,
    left_button_text,
    left_button_link,
    right_button_text,
    right_button_link
  } = primary
  return (
    <div className={styles.wrapper} style={{ backgroundColor }}>
      <div className={styles.mobileImage}>
        <img src={mobileImageUrl} alt={mobileImageAlt} loading="lazy" />
      </div>
      <div className={styles.detailsWrapper}>
        <div className={styles.details}>
          <div className={styles.text}>
            <RichText render={header_text} />
            <RichText render={text} />
          </div>
          <div className={styles.buttons}>
            <LinkButton to={left_button_link}>
              {left_button_text}
            </LinkButton>
            <LinkButton to={right_button_link}>
              {right_button_text}
            </LinkButton>
          </div>
        </div>
      </div>
      <div className={styles.desktopImage}>
        <img src={desktopImageUrl} alt={desktopImageAlt} loading="lazy" />
      </div>
    </div>
  )
}

export default Brands