import React from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import Button from 'src/components/button'
import Link from 'src/components/link'
import styles from 'src/css/slices/content_area.module.css'
import { classes } from 'src/utilities/classes'
import MediaWidthWrapper from 'src/components/media_width_wrapper'
import LeadSignUp from "src/components/lead_sign_up"

export default ({ primary, fields, idPrefix, sliceIndex }) => {
  const {
    minHeight,
    backgroundImage,
    backgroundGradient,
    backgroundPosition,
    backgroundColor,
    backgroundSize,
    padding,
    width,
    textAlign,
    verticalAlign,
    mediaWidthRange
  } = primary

  const innerStyle = {
    boxSizing: 'border-box',
    padding: padding,
    minHeight,
    flex: (width || '100%'),
    backgroundImage: [backgroundImage ? `url(${backgroundImage.url})` : undefined, backgroundGradient].filter(b => !!b).join(', '),
    backgroundRepeat: 'no-repeat',
    backgroundPosition,
    backgroundSize: backgroundSize || 'contain',
    backgroundColor,
    textAlign,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: verticalAlignAsFlex[verticalAlign],
  }
  const blockAlignment = textAlign === 'center' ? { marginLeft: 'auto', marginRight: 'auto' } : textAlign === 'right' ? { marginLeft: 'auto' } : {}

  const LinkWrapper = ({ link, children }) => {
    if (link) {
      return <Link to={link}>{children}</Link>
    } else {
      return children
    }
  }

  const renderImage = ({ image, width, maxWidth, link, padding }, idx) =>
    <div key={idx}>
      <LinkWrapper link={link}>
        <Image style={{ width, maxWidth: (maxWidth || '100%'), padding, height: 'auto', boxSizing: 'border-box', display: 'block', ...blockAlignment }}>{image}</Image>
      </LinkWrapper>
    </div>

  const renderRichText = ({ richText, link, maxWidth, fontSize, padding, lineHeight, foregroundColor: color, backgroundColor, linkColor, width }, idx) =>
    <LinkWrapper link={link} key={idx}>
      <RichText className={classes(styles.richText, !!padding && styles.withPadding)} style={{ maxWidth, fontSize, padding, lineHeight, color, backgroundColor, width, "--link-color": (linkColor || 'currentColor'), ...blockAlignment }}>
        {richText}
      </RichText>
    </LinkWrapper>

  const renderArrowButton = (item, idx) => renderButton(item, idx, { arrow: true })
  const renderButton = ({ text, link, padding, foregroundColor: color, backgroundColor, width }, idx, { arrow = false } = {}) =>
    <LinkWrapper link={link} key={idx}>
      <div style={{ padding }}>
        <Button withArrow={arrow} style={{ color, backgroundColor, width }}>{text}</Button>
      </div>
    </LinkWrapper>

  const renderHtml = ({ text, link, maxWidth, fontSize, padding, lineHeight, color, backgroundColor, width }, idx) =>
    <LinkWrapper link={link} key={idx}>
      <div dangerouslySetInnerHTML={{ __html: text }} style={{ maxWidth, fontSize, padding, lineHeight, color, backgroundColor, width, ...blockAlignment }} />
    </LinkWrapper>

  const renderSignUp = (item, idx) => <LeadSignUp key={idx} {...item} />

  const renderUnknown = () => false

  const renderers = {
    'Image': renderImage,
    'Rich Text': renderRichText,
    'Button': renderButton,
    'Arrow Button': renderArrowButton,
    'Sign Up': renderSignUp,
    'HTML': renderHtml
  }

  return (
    <MediaWidthWrapper range={mediaWidthRange}>
      <div id={`${idPrefix}-content-area-${sliceIndex}`} style={innerStyle} className={classes(styles.contentArea)}>
        {fields.map((element, idx) => (renderers[element.type] || renderUnknown)(element, idx))}
      </div>
    </MediaWidthWrapper>
  )
}

const verticalAlignAsFlex = {
  top: 'flex-start',
  middle: 'center',
  bottom: 'flex-end',
}