import { graphql } from "gatsby"
export const ShopDirectory = graphql`
  fragment ShopDirectory on PRISMIC_Shop_directorConnectionConnection {
    edges {
        node {
          _meta {
            ...Meta
          }
          ...ShopDirectorySEO
          order_types_in_the_category_menu {
            order_type_name
          }
          body {
            ... on PRISMIC_Shop_directorBodyLatest {
              type
              fields {
                button_label
                button_link {
                  ...Link
                }
                button_color
                button_text_color
                merchant_image
              }
              primary {
                heading
                heading_color
                image
                background_color
              }
            }
            ... on PRISMIC_Shop_directorBodyFeatured {
              type
              label
              fields {
                button_label
                button_link {
                  ...Link
                }
                button_color
                button_text_color
                feature_text
                feature_text_color
                merchant_image
                merchant_name
                merchant_name_color
              }
              primary {
                heading
                heading_color
                image
                background_color
              }
            }
            ... on PRISMIC_Shop_directorBodyShop_directory {
              type
              label
              primary {
                background_image
                keyword_match_text
                repeat_keyword_search_all_categories_text
                search_bar_header_text
                search_bar_header_text_color
                search_bar_placeholder_text
                tile_hover_color_1
                tile_hover_color_2
                tile_hover_color_3
                load_more_text
                load_more_text_color
                load_more_background_color
                load_more_outline_color
                load_more_hover_text_color
                load_more_hover_background_color
                hide_online_in_store_toggles
              }
            }
            ... on PRISMIC_Shop_directorBodyFull_width_promo {
              type
              label
              primary {
                promo_image
                promo_image_mobile
                promo_url {
                  ...Link
                }
              }
            }
            ... on PRISMIC_Shop_directorBodyDual_promo {
              type
              label
              primary {
                background_color
                link_left { ...Link }
                desktop_image_left
                mobile_image_left
                link_right { ...Link }
                desktop_image_right
                mobile_image_right
              }
            }
          }
        }
      }
  }
`