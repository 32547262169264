import React from 'react'
import {linkResolver} from 'src/utilities/linkResolver'
import PageContext from 'src/components/page_context'
import { Link as GatsbyLink } from "gatsby"

const Link = ({to, children, className, target=undefined, rel=undefined, ...props}) =>
  <PageContext.Consumer>
    {({lang}) => {
      let path
      if (to === undefined || to === null) {
        path = undefined
      } else if (typeof to === 'string')
        path = to
      else if (to && to._meta)
        path = linkResolver({lang, ...to._meta})
      else
        path = linkResolver({lang, ...to})

      if ((to || {}).target) {
        target = to.target
      }

      if (`${path}`.includes('://') || `${path}`.startsWith('mailto') || path === undefined || target === '_blank')
        return <a target={target} rel={rel} href={path} className={className} {...props}>{children}</a>
      else
        return <GatsbyLink to={path} className={className} {...props}>{children}</GatsbyLink>
    }}
  </PageContext.Consumer>

export default Link
