import React from "react"
import styles from 'src/css/slices/mobile_payment.module.css'
import Image from 'src/components/image'
import LinkButton from 'src/components/link_button'
import { RichText } from 'prismic-reactjs'

const MobilePayment = ({ primary }) => {
  const { feature_image, feature_image_mobile, header_text, logo, button_text, button_link, fine_print, background_color: backgroundColor } = primary

  return (
    <div className={styles.wrapper} style={{backgroundColor}}>
      <div className={styles.inner}>
        <div className={styles.left} style={{backgroundImage: feature_image ? `url("${feature_image.url}")` : undefined}}>

        </div>
        <div className={styles.right}>
          <RichText render={header_text} />
          <Image className={styles.logo}>{logo}</Image>
          <Image className={styles.featureImageMobile}>{feature_image_mobile}</Image>
          {button_text && <LinkButton to={button_link} className={styles.button}>
            {button_text}
          </LinkButton>}
        </div>
      </div>
      <div className={styles.finePrint}>
        <RichText render={fine_print} />
      </div>
    </div>
  )
}

export default MobilePayment