import React from 'react'
import { kebabize } from '../utilities/string'
import { Helmet } from "react-helmet"

export default function DynamicStyle({children, dynamicStyle, className}) {
  // This component assumes a single child with either an id or a single className
  // It is designed to help with cases where inline styles don't work (specifically pseudo classes such as hover)
  const identifier = className ? `.${className}` : children.props.id ? `#${children.props.id}` : `.${children.props.className}`
  const buildCss = (style,pseudo='') => {
    const hasEntries = Object.entries(style).some(([key, value]) => !(value === null || value === undefined || typeof(value) == 'object'))
    return Object.entries(style).reduce((css, [key, value]) => {
      if (value === null || value === undefined) {
       return css
      } else if (typeof(value) == 'object') {
        const nested = buildCss(value, key.replace('&', ''))
        return nested ? `${nested}\n${css}` : css
      } else {
        return css + `\n  ${kebabize(key)}: ${value};`
      }
    }, (hasEntries ? `${identifier}${pseudo} {` : '')) + (hasEntries ? '\n}' : '')
  }
  const css = buildCss(dynamicStyle)
  return (
    <>
      {!!css && <Helmet style={[{cssText: css}]} /> }
      {children}
    </>
  )
}