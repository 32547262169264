import React from 'react'
import styles from 'src/css/button.module.css'
import {classes} from 'src/utilities/classes'
import ArrowRight from 'src/images/arrow_right'

export default function Button({children, withArrow, inverted, className, ...props}) {
  return (
    <button {...props} className={classes("global-button-style", className, styles.button, inverted && styles.inverted, inverted && "global-button-style-inverted")}>
      {children} {withArrow && <ArrowRight/>}
    </button>
  )
}