import React, { useState, useContext } from "react"
import { Helmet } from "react-helmet"
import Button from 'src/components/button'
import Checkbox from 'src/components/checkbox'
import PageContext from 'src/components/page_context'
import styles from 'src/css/components/lead_sign_up.module.css'
import { withRecaptcha, postData } from 'src/utilities/request'
import { countryIds } from "../utilities/locale"

const LeadSignUp = ({ text, link, width, backgroundColor, padding, fontSize, lineHeight, maxWidth }) => {
  const [email, setEmail] = useState('')
  const [join, setJoin] = useState(false)
  const [loading, setLoading] = useState(false)
  const pageContext = useContext(PageContext)
  const countryid = countryIds[pageContext?.lang]

  const [source, placeholder, buttonText, joinCheckboxText] = (text || '').split('\n')

  const handleClick = async () => {
    setLoading(true)
    const url = new URL(link.url)
    try {
      if (email) {
        global.dataLayer.push({ 'event': 'lead_submitted', source, countryid, subscribe: (join || !joinCheckboxText) })
        const response = await subscribe({ email, source, countryid, subscribe: (join || !joinCheckboxText) })
        const { token } = await response.json()
        url.searchParams.set('lead_token', encodeURIComponent(token))
      }
    } catch { }
    window.location.href = url;
  }

  return <div className={styles.signUp} style={{ padding }}>
    <Helmet bodyAttributes={{ class: "landing-page-with-recaptcha" }}>
      <script async src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}></script>
    </Helmet>
    <input type="email" className={styles.emailInput} placeholder={placeholder} style={{ width: (width || '280px') }} value={email} onChange={({ target: { value } }) => setEmail(value)} />
    <Button disabled={loading} onClick={handleClick} style={{ color: 'white', backgroundColor }}>{buttonText}</Button>
    {joinCheckboxText && <div className={styles.mailingListConfirm}>
      <Checkbox label={joinCheckboxText} checked={join} onChange={({ target: { checked } }) => setJoin(checked)} style={{ fontSize, lineHeight, maxWidth }} />
    </div>}
  </div>
}

const subscribe = async (params) => {
  return await withRecaptcha(async function (recaptchaToken) {
    try {
      return await postData(
        `${process.env.GATSBY_PREFERENCE_CENTRE_API_URL}/campaigns/sign_up`,
        { data: { attributes: { ...params, recaptchaToken } } }
      )
    } catch { }
  })
}

export default LeadSignUp