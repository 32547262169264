import React, {useEffect, useReducer, useState} from 'react'
import Header from '../components/header'
import Menu from '../components/menu'
import Filters from '../components/filters'
import Tile from '../components/tile'
import DynamicStyle from '../../components/dynamic_style'
import { optionalThreeColorGradient } from '../../utilities/color'
import styles from '../styles/shop-directory.module.css'
import {getCategory, getTiles} from '../api'
import qs from 'qs'
import { langAliases } from '../../utilities/locale'

const initialState = {
  tiles: [null, null, null, null, null, null, null, null],
  categoriesById: {},
  categoriesBySlug: {}
}

const buildCategoryMaps = (category, categoriesById={}, categoriesBySlug={}) => {
  categoriesById[category.id] = category;
  categoriesBySlug[category.slug] = category;
  (category.children || []).forEach(child => buildCategoryMaps(child, categoriesById, categoriesBySlug))
  return { categoriesById, categoriesBySlug}
}

const ShopDirectory = ({
    locale,
    background_image: backgroundImage,
    keyword_match_text: keywordMatchText,
    repeat_keyword_search_all_categories_text: repeatKeywordSearchAllCategoriesText,
    search_bar_header_text: searchBarHeaderText,
    search_bar_header_text_color: searchBarHeaderTextColor,
    search_bar_placeholder_text: searchBarPlaceholderText,
    load_more_text: loadMoreText,
    load_more_text_color: loadMoreTextColor,
    load_more_background_color: loadMoreBackgroundColor,
    load_more_hover_text_color: loadMoreHoverTextColor,
    load_more_hover_background_color: loadMoreHoverBackgroundColor,
    load_more_outline_color: loadMoreOutlineColor,
    tile_hover_color_1: tileHoverColor1,
    tile_hover_color_2: tileHoverColor2,
    tile_hover_color_3: tileHoverColor3,
    hide_online_in_store_toggles,
    orderTypesInTheCategoryMenu,
    siteSettings
  }) => {
  const [state, setState] = useReducer((prev, next) => ({...prev,...next}), initialState)
  const [debounce, setDebounce] = useState()
  const localeCode = (langAliases[locale] || '').toUpperCase()
  const { countryCategory, tiles } = state
  const queryParams = typeof window !== 'undefined' ? qs.parse(window.location.search.slice(1)) : {}
  const { categoryId: currentCategoryId, subCategoryId: currentSubCategoryId, ...filter } = queryParams
  const categories    = countryCategory ? countryCategory.children : []
  const categorySlug  = typeof window !== 'undefined' ? (window.location.pathname.match(/shop-here\/([^/]+)/) || [])[1] : null
  const category      = state.categoriesBySlug[categorySlug] || countryCategory
  const parent        = state.categoriesById[(category || {}).parentId] || {}
  const isSubCategory = parent.id && parent !== countryCategory
  const orderTypes    = (category || {}).orderTypes || []
  const currentOrder  = queryParams.order || (orderTypes[0] || {}).name
  const remainingTiles = state.totalTiles - tiles.length
  const pages          = (queryParams.pages || (category && category.initialPages) || 1) * 1

  const loadMoreStyle = {
    color: loadMoreTextColor || '#000',
    backgroundColor: loadMoreBackgroundColor || 'transparent',
    boxShadow: `inset 0 0 0 2px ${loadMoreOutlineColor || '#000'}`,
    '&:hover': {
      color: loadMoreHoverTextColor || '#fff',
      backgroundColor: loadMoreHoverBackgroundColor || '#000'
    }
  }
  const countryOrderTypeNames = ((countryCategory || {}).orderTypes || []).map(ot => ot.name)
  const menuOrderTypes = (orderTypesInTheCategoryMenu || []).filter(ot => countryOrderTypeNames.includes(ot.order_type_name))

  const updateFilter = ({categorySlug: updatedCategorySlug, preserveSearchText=false, ...nextFilter}, reload=true) => {

    updatedCategorySlug = updatedCategorySlug === undefined ? category.slug : updatedCategorySlug
    updatedCategorySlug = updatedCategorySlug === countryCategory.slug ? undefined : updatedCategorySlug

    const updatedQueryParams = {
      ...filter,
      ...nextFilter
    }

    const newCategory        = state.categoriesBySlug[updatedCategorySlug] || countryCategory || {}
    const orderTypes         = (newCategory || {}).orderTypes || []
    const defaultOrderType   = orderTypes[0] || {}
    const currentOrderType   = orderTypes.find(o => updatedQueryParams.order === o.name) || defaultOrderType
    const categoryChanged    = (!categorySlug && !updatedCategorySlug) ? false : (categorySlug !== updatedCategorySlug)

    updatedQueryParams.order = updatedQueryParams.order === null ? undefined :
      ((categoryChanged && !nextFilter.order) ? defaultOrderType : currentOrderType).name

    if((category || {}).id !== (newCategory || {}).id){
      delete updatedQueryParams.pages
      if (newCategory.initialPages) {
        updatedQueryParams.pages = newCategory.initialPages
      }
      if(!preserveSearchText)
        delete updatedQueryParams.searchText
      if((newCategory || {}).name){
        const { name , label, id } = newCategory
        global.dataLayer.push({ 'event':'ShopHereCategory', 'category': { name , label, id } })
      }
    }

    if(typeof window !== 'undefined' && window){
      const updatedPath  = window.location.pathname.replace(/(^.*?shop-here).*/,'$1')+`/${updatedCategorySlug || ''}`
      const updatedQuery = Object.entries(updatedQueryParams).reduce((agg, [k,v]) => (v ? {...agg, [k]: v} : agg), {})
      const queryString = qs.stringify(updatedQuery)
      window.history.replaceState({}, '', `${updatedPath}${queryString ? `?${queryString}` : ''}`)
    }
    reload ? setState({tiles: initialState.tiles}) : setState({})
  }

  const updateSearchText = searchText => {
    if(typeof window !== 'undefined' && window){
      window.clearTimeout(debounce)
      setDebounce(window.setTimeout(() => updateFilter({searchText, pages: undefined}), 500))
    }
    updateFilter({searchText, pages: undefined}, false)
  }

  const onSearchTextChange = ({target: { value: searchText }}) => {
    updateSearchText(searchText)
  }

  const onClearSearch = () => {
    updateSearchText('')
  }

  const handleLoadMore = async() => {
    setState({tiles: [...tiles, ...initialState.tiles.filter((t, i) => i < remainingTiles)]})
    updateFilter({pages: pages + 1}, false)
    await loadTiles(pages + 1)
  }

  const repeatSearchGlobally = () => {
    updateFilter({categorySlug: null, preserveSearchText: true, searchText: queryParams.searchText}, true)
    if(typeof window !== 'undefined' && window){
      window.scrollTo(0, 0)
    }
  }

  const loadTiles = async(pages) => {
    const tileCount = tiles.filter(t => t).length
    const pageSize = initialState.tiles.length
    const pageNumber = tileCount / pageSize + 1
    const fetchTiles = async (pageNumber, reloadAll = false) => await getTiles({
      page: {
        size: (pages * pageSize - (reloadAll ? 0 : tileCount)),
        number: pageNumber
      },
      categoryId: category.id,
      filter: {
        order: currentOrder,
        ...filter
      }
    })
    const { data: newTiles, meta } = await fetchTiles(pageNumber)
    const newTileIds = newTiles.map(t => t.id)

    // If there are no duplicates add the new tiles to state
    if (!tiles.some(t => (t && newTileIds.indexOf(t.id) > -1))) {
      setState({
        tiles: [...tiles.filter(t => t), ...newTiles],
        totalTiles: meta.count
      })
    } else {
      // If there are duplicates reload everything, the order has clearly changed
      const { data: allTiles, meta: allMeta } = await fetchTiles(0, true)
      setState({
        tiles: [...allTiles],
        totalTiles: allMeta.count
      })
    }
  }


  useEffect(() => {
    (async () => {

      const shouldLoadMoreTagMatches = () => {
        const moreTilesAvailable = state.tiles.length < state.totalTiles
        const endOfLastPage = state.tiles[state.tiles.length - 1]
        const beginningOflastPage = state.tiles[state.tiles.length - initialState.tiles.length]
        return moreTilesAvailable && endOfLastPage && beginningOflastPage && endOfLastPage.tagMatch && !beginningOflastPage.tagMatch
      }

      if (!countryCategory){
        getCategory(localeCode).then(result =>{
          const { categoriesById, categoriesBySlug } = buildCategoryMaps(result.data)
          setState({countryCategory: result.data, categoriesById, categoriesBySlug, defaultCatSelected: false })
        })
      }
      else if (state.tiles === initialState.tiles) {
        if (!state.defaultCatSelected && countryCategory.currentDefaultCategoryId && state.categoriesById[countryCategory.currentDefaultCategoryId]) {
          setState({ defaultCatSelected: true })
          updateFilter({ categorySlug: state.categoriesById[countryCategory.currentDefaultCategoryId].slug })
        } else {
          setState({tiles: [...state.tiles]})
          loadTiles(pages)
        }
      }
      else if(shouldLoadMoreTagMatches()){
        handleLoadMore()
      }
    })()
  })

  const background = category && category.heroBannerUrl ? { url: (category && category.heroBannerUrl) } : backgroundImage
  const { nameMatches, tagMatches }= tiles.reduce(
    ({nameMatches, tagMatches}, elm) => {
      return {
        nameMatches: (elm && !elm.tagMatch) || (!elm && !tagMatches.length) ? [...nameMatches, elm] : nameMatches,
        tagMatches:  (elm && elm.tagMatch) ||  (!elm && !!tagMatches.length) ? [...tagMatches, elm] : tagMatches
      }
    },
    {nameMatches: [], tagMatches: []}
  )

  const tileHoverBackground = optionalThreeColorGradient(tileHoverColor1, tileHoverColor2, tileHoverColor3)
  return (
    <div className={styles.wrapper}>
      <Header backgroundImage={background} headerText={searchBarHeaderText} headerTextColor={searchBarHeaderTextColor} placeholderText={searchBarPlaceholderText} filter={filter} onFilterChange={updateFilter} onSearchTextChange={onSearchTextChange} onClearSearch={onClearSearch} hideOnlineInStoreToggles={hide_online_in_store_toggles}/>
      <Menu category={category}  order={queryParams.order} orderTypes={menuOrderTypes} categories={categories} onChange={updateFilter}/>
      <DynamicStyle className="tile-hover-gradient" dynamicStyle={{
        '&:before': { background: tileHoverBackground },
        '&:after': { background: tileHoverBackground }
      }} />
      <Filters
        onChange={updateFilter}
        queryParams={queryParams}
        order={currentOrder}
        title={category  && category !== countryCategory ? category.name : 'All Shops'}
        category={isSubCategory ? parent : category === countryCategory ? null : category}
        isSubCategory={isSubCategory}
        subCategory={category}
        orderTypes={orderTypes || []}
        categories={categories}
        subCategories={((isSubCategory || !parent.id ? parent : category) || {}).children || []}
      />
      {
        !!nameMatches.length &&
        <div className={styles.tiles}>
          {nameMatches.map((tile, i) => <Tile key={i} tile={tile} siteSettings={siteSettings} />)}
        </div>
      }
      {
        !!tagMatches.length &&
        <div className={`${styles.tiles} ${styles.tagMatches}`}>
          <h4 className={styles.matchText}>{keywordMatchText || "Results that match:"} {category !== countryCategory ? `${category.name} > ` : '' }"{queryParams.searchText}"</h4>
          {
            category !== countryCategory ?
              <p className={styles.globalSearchLink} onClick={repeatSearchGlobally}>{repeatKeywordSearchAllCategoriesText || "Search all categories for"} "{queryParams.searchText}"</p> :
              false
          }
          {tagMatches.map((tile, i) => <Tile key={i} tile={tile} siteSettings={siteSettings} />)}
        </div>
      }
      {remainingTiles > 0 && <DynamicStyle dynamicStyle={loadMoreStyle}>
        <button className={styles.loadMore} onClick={handleLoadMore}>
          {loadMoreText || "Load More"}
        </button>
      </DynamicStyle>}
      <div id='modal-root'></div>
    </div>
  )
}

export default ShopDirectory