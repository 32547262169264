import { graphql } from "gatsby"
export const SiteSettings = graphql`
  fragment SiteSettings on PRISMIC_Site_settingsConnectionConnection {
    edges {
      node {
        button_color
        button_hover_color_1
        button_hover_color_2
        button_hover_color_3
        button_text_color
        button_text_hover_color

        promo_teaser_icon
        promo_code_header_text
        promo_copy_code_button_text
        promo_copied_code_button_text
        promo_start_shopping_button_text
        tap_to_pay_popup_image
        tap_to_pay_header_text
        tap_to_pay_description
        tap_to_pay_browse_button_text
        tap_to_pay_browse_link { ...Link }
        tap_to_pay_view_button_text
        tap_to_pay_video_link { ...Link }
        affiliate_overlay_text
        affiliate_overlay_gradient
        affiliate_popup_text
        affiliate_proceed_to_text
        affiliate_app_qr_code
        affiliate_app_store_button
        affiliate_google_play_button
        affiliate_app_store_link { ...Link }
        affiliate_google_play_link { ...Link }
        affiliate_app_logo

        _meta {
          lang
        }
      }
    }
  }
`