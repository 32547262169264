import React, { Component } from 'react'
import styles from 'src/css/slices/how_it_works.module.css'
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import DynamicStyle from 'src/components/dynamic_style'

class HowItWorks extends Component {
  render = () => {
    const { fields, primary, sliceIndex } = this.props
    const { feature_image, heading, heading_color } = primary
    return (
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div className={styles.featureImage}>
            <Image dpiRatio={2}>{feature_image}</Image>
          </div>
          <div className={styles.content}>
            <RichText className={styles.heading} style={{ color: heading_color }}>{heading}</RichText>
            <Image className={styles.featureImageMobile}>{feature_image}</Image>
            <ul>
              {fields.map(({ item_image, item_text, item_text_color, item_text_header_color }, i) =>
                <DynamicStyle key={i} dynamicStyle={{
                  color: item_text_color,
                  ...([1, 2, 3, 4, 5, 6].reduce((o, x) => ({ ...o, [`& h${x}`]: { color: item_text_header_color } }), {}))
                }}>
                  <li id={`slice-${sliceIndex}-how-it-works-${i}`}>
                    <RichText>{item_text}</RichText>
                  </li>
                </DynamicStyle>
              )}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default HowItWorks