import React from "react"
import styles from 'src/css/slices/why_laybuy.module.css'
import LinkButton from 'src/components/link_button'
import Image from 'src/components/image'
import Link from 'src/components/link'
import { RichText } from 'prismic-reactjs'

const WhyLaybuy = ({ primary, fields }) => {
  const {
    feature_image,
    heading,
    description,
    button_text,
    button_link
  } = primary
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <RichText render={heading} />
          <div className={styles.description}>
            <RichText render={description} />
          </div>
          <div className={styles.featureImageMobileWrapper}>
            <div className={styles.featureImageMobile}>
              <Image>{feature_image}</Image>
            </div>
          </div>
          <div className={styles.benefits}>
            {fields.map(({benefit_text, benefit_link}, i) => <div key={i}>
              <img src="/images/tick.svg" alt="Tick" />
              {benefit_text}
              {benefit_link && <Link to={benefit_link} target="_blank"><div>?</div></Link>}
            </div>)}
          </div>
          <LinkButton to={button_link}>{button_text}</LinkButton>
        </div>
        <div className={styles.right}>
          <Image className={styles.featureImage}>{feature_image}</Image>
        </div>
      </div>
    </div>
  )
}

export default WhyLaybuy