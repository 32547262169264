import React from "react"
import styles from 'src/css/slices/features.module.css'
import LinkButton from 'src/components/link_button'
import { RichText } from 'prismic-reactjs'

const Features = ({ fields }) => {
  return (
    <div className={styles.wrapper}>
      {fields.map(({ background_image, background_color: backgroundColor, header_text, button_text, button_link }, i) =>
        <div key={i} className={styles.feature} style={{ backgroundColor, backgroundImage: `url("${background_image.url}")` }}>
          <div className={styles.headerDiv}>
            <RichText render={header_text} />
          </div>
          <div className={styles.spacerDiv}></div>
          <div className={styles.buttonDiv}>
            <LinkButton to={button_link}>
              {button_text}
            </LinkButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default Features