import { graphql } from "gatsby"
export const OurMerchants = graphql`
  fragment OurMerchants on PRISMIC_For_merchants_15ConnectionConnection {
    edges {
      node {
        _meta {
          ...Meta
        }
        ...OurMerchantsSEO
        body {
          ... on PRISMIC_For_merchants_15BodyInfinite_growth {
            type
            label
            primary {
              heading
              subheading
              description
              button_text
              button_link { ...Link }
              background_color
              hero_image
              hero_image_mobile
            }
          }
          ... on PRISMIC_For_merchants_15BodyWhy_laybuy {
            type
            label
            primary {
              heading
              description
              button_text
              button_link { ...Link }
              feature_image
            }
            fields {
              benefit_text
              benefit_link { ...Link }
            }
          }
          ... on PRISMIC_For_merchants_15BodyFull_width_promo {
            type
            label
            primary {
              promo_image
              promo_image_mobile
              promo_url { ...Link }
            }
          },
          ... on PRISMIC_For_merchants_15BodyIn_good_company {
            type
            label
            primary {
              heading
            }
            fields {
              logo
              description
              signature
            }
          },
          ... on PRISMIC_For_merchants_15BodyHow_it_works {
            type
            fields {
              item_image
              item_text
              item_text_color
              item_text_header_color
            }
            primary {
              feature_image
              heading
              heading_color
            }
          }
          ... on PRISMIC_For_merchants_15BodyPartners {
            type
            primary {
              left_button_label
              left_button_link {
                ...Link
              }
              right_button_label
              right_button_link {
                ...Link
              }
              feature_image
              text
              gradient_color_start
              gradient_color_end
            }
          }
          ... on PRISMIC_For_merchants_15BodyDashboard {
            type
            primary {
              background_color
              button_label
              button_link {
                ...Link
              }
              button_color
              button_text_color
              feature_image
              text
            }
          }
        }
      }
    }
  }
`