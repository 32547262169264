import React, {Component} from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import Button from 'src/components/button'
import Link from 'src/components/link'
import styles from 'src/css/slices/payment.module.css'
import CarouselDots from 'src/components/carousel_dots'
import {classes} from 'src/utilities/classes'
import DynamicStyle from 'src/components/dynamic_style'

class Payment extends Component {
  state = {index: 0}

  handleIndexChange = index => {
    this.setState({index})
  }

  render = () => {
    const {fields,primary} = this.props

    return (
      <div className={styles.wrapper} style={{backgroundColor: primary.background_color}}>
        <div className={styles.payment}>
          <div className={styles.inner}>
            <div className={styles.itemContainer}>
              {fields.map((field, index) =>
                <div className={classes(styles.item, index === this.state.index && styles.active)} key={index}>
                  <div className={classes(styles.featureImage, index === 1 && styles.alignTop)}>
                    <picture>
                      {field.mobile_feature_image &&
                      <source media='(max-width: 600px)' srcSet={field.mobile_feature_image.url}/>
                      }
                      <Image dpiRatio={2}>{field.feature_image}</Image>
                    </picture>
                  </div>
                  <div className={styles.content}>
                    <Image className={styles.smallImage} dpiRatio={2}>{field.small_image}</Image>
                    <RichText className={styles.description} style={{color: field.description_color}}>{field.description}</RichText>
                    <Link to={field.button_link}>
                      <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: field.button_text_color }, background: field.button_color }}>
                        <Button id={`payment-slice-button-${index}`}>{field.button_label}</Button>
                      </DynamicStyle>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <CarouselDots index={this.state.index} count={fields.length} className={styles.carouselDots}
                          onIndexChange={this.handleIndexChange}/>
          </div>
        </div>
      </div>
    )
  }
}

export default Payment