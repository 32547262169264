import React, { useState } from 'react'
import styles from '../styles/tile.module.css'
import { classes } from '../utils/classes'
import Modal from '../components/modal'
import TapEnabled from 'src/images/tap_enabled'
import { PromoModalContent } from './promo_modal_content'
import { TapToPayModalContent } from './tap_to_pay_modal_content'
import { AffiliateModalContent } from './affiliate_modal_content'

const Tile = ({ tile, loading, siteSettings }) => {
  const activePromotion = tile ? tile.activePromotion : false
  let { currentTileUrl, currentLogoUrl } = tile ? tile : {}
  if (process.env.GATSBY_SHOP_DIRECTORY_IMAGE_PARAMS) {
    currentTileUrl = appendImageParams(currentTileUrl)
  }

  const currentUrl = activePromotion ? activePromotion.currentUrl : (tile || {}).currentUrl
  const [modalOpen, setModalOpen] = useState(false)
  const [modalType, setModalType] = useState('promo')
  const tileAnnotation =
    !tile         ? '' :
    tile.global   ? 'Global' :
    !tile.instore ? '' :
    tile.online   ? 'Also In Store' : 'In Store Only'

  const { id, callToAction } = (tile || {})

  const promoPopup = activePromotion && !modalOpen && activePromotion.currentUrl && activePromotion.currentUrl.indexOf("pop=1") !== -1
  const openPromoModal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setModalType('promo')
    setModalOpen(true)
  }

  const openTapEnabledModal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setModalType('tapEnabled')
    setModalOpen(true)
  }

  const openAffiliateModal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setModalType('affiliate')
    setModalOpen(true)
  }

  const tileImage = (!!tile &&
    <div className={styles.tileImages}>
      <div className={styles.tileImage}>
        <img src={currentTileUrl ? `${process.env.GATSBY_SHOP_DIRECTORY_ASSET_HOST || ''}${currentTileUrl}` : ''} alt='shop logo' loading={loading} />
      </div>
      {
        currentLogoUrl ? <div className={styles.tileDarkOverlay} /> : false
      }
      {
        currentLogoUrl ?
          <div className={styles.logoImage}>
            <img src={`${process.env.GATSBY_SHOP_DIRECTORY_ASSET_HOST || ''}${currentLogoUrl}`} alt='shop logo' loading={loading} />
          </div> :
          false
      }
    </div>)

  const onCloseModal = () => setModalOpen(false)

  const { promo_teaser_icon } = (siteSettings || {})
  const promoTeaserIconSrc = (promo_teaser_icon && promo_teaser_icon.url) || "/images/laybuy-emoji.png"
  const canShowAffiliate = !!tile?.currentAffiliateImageUrl && tile?.isAffiliateMerchant

  return (
    <a onClick={promoPopup ? openPromoModal : canShowAffiliate ? openAffiliateModal : tile ?
      () => global.dataLayer.push({ 'event': 'merchantclick', 'merchantname': tile.name }) :
      undefined
    } className={styles.tile} {...tile && { href: currentUrl, target: '_blank', rel: 'noopener noreferrer' }}>
      {!!id && !!callToAction && <style type="text/css">{`
        #tile-img-border-${tile.id}::after {
          content: '${callToAction.replace(/'/g, "\\'")}   ' url(/images/right_arrow.svg)
        }
      `}</style>}
      <div className={classes(styles.imgBorder, "tile-hover-gradient")} id={id ? `tile-img-border-${id}` : undefined}>
        <div className={styles.imgWrapper}>
          {
            tileImage
          }
          {
            activePromotion &&
            <button className={styles.activePromotion} onClick={openPromoModal}>
              <img src={promoTeaserIconSrc} alt="Promotion" />
              <span>{activePromotion.caption}{!!activePromotion.code && '*'}</span>
            </button>
          }
          {
            tile && tile.tapToPay &&
            <button className={styles.tapEnabledFlag} onClick={openTapEnabledModal}>
              <span className={styles.tapEnabled}>
                <TapEnabled />
                {'Tap Enabled'}
              </span>
            </button>
          }
        </div>
      </div>
      {
        modalOpen &&
        <>
          <style type="text/css">{`body { overflow-y: hidden; }`}</style>
          <Modal onClose={onCloseModal}>
            {modalType === 'tapEnabled' ?
              <TapToPayModalContent {...{ onCloseModal, siteSettings }} /> :
              modalType === 'affiliate' ?
              <AffiliateModalContent {...{ tile, onCloseModal, siteSettings } } /> :
              <PromoModalContent {...{ tile, tileImage, activePromotion, onCloseModal, siteSettings }} />
            }
          </Modal>
        </>}
      <div className={styles.content}>
        {tile ?
          <>
            <h3>{tile.name}</h3>
            {tileAnnotation &&
              <span className={styles.inStore}>
                {tileAnnotation}
              </span>
            }
            {tile.tapToPay &&
              <span className={styles.tapEnabled} onClick={openTapEnabledModal}>
                <TapEnabled />
                {'Tap Enabled'}
              </span>
            }
          </>
          :
          <span className={styles.loader} />
        }
      </div>
    </a>
  )
}

const imageParams = (process.env.GATSBY_SHOP_DIRECTORY_IMAGE_PARAMS || '').split('&').map(p => p.split('='))
const appendImageParams = (imageUrl) => {
  if ((typeof window === 'undefined') || 'URLSearchParams' in window) {
    try {
      const url = new URL(imageUrl);
      imageParams.forEach(([k, v]) => url.searchParams.set(k, v))
      return url.toString()
    } catch { // Better to return the original than not display anything
      return imageUrl
    }
  }
  return imageUrl ? `${imageUrl}?${process.env.GATSBY_SHOP_DIRECTORY_IMAGE_PARAMS}` : imageUrl
}

export default Tile