import React, {Component} from "react"
import styles from 'src/css/slices/full_width_promo.module.css'
import Image from 'src/components/image'
import Link from 'src/components/link'
import MediaWidthWrapper from 'src/components/media_width_wrapper'

class FullWidthPromo extends Component {
  render = () => {
    const { primary: { promo_image, promo_image_mobile, promo_url, media_width_range } } = this.props
    return promo_image ? (
      <MediaWidthWrapper range={media_width_range}>
        <div id={`full-width-promo-${this.props.sliceIndex}`} className={styles.wrapper}>
          <Link target='_blank' to={promo_url}>
            <picture>
              {promo_image_mobile && <source srcSet={promo_image_mobile.url} media="(max-width: 600px) and (max-aspect-ratio: 1/1)" />}
              <Image>{promo_image}</Image>
            </picture>
          </Link>
        </div>
      </MediaWidthWrapper>
    ) : null
  }
}

export default FullWidthPromo