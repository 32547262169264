import React, {useState} from 'react'
import styles from '../styles/filters.module.css'
import {classes} from '../utils/classes'
import World from '../images/world'
import ChevronDown from '../images/chevron_down'

const Filters = ({isSubCategory, order, orderTypes, category, subCategory, categories, subCategories, onChange, title, queryParams: { localOnly, searchText}}) => {
  const [selectOpen, setSelectOpen] = useState({})

  const handleCategorySelected = (c) => {
    if (c.externalUrl) {
      window.open(c.externalUrl, '_blank')
    } else {
      onChange({categorySlug: c.slug})
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {
          typeof window !== 'undefined' ?
          <div className={classes(styles.item, styles.shopTheWorld)}>
            <div className={classes(styles.switch, localOnly ? styles.off : '')} onClick={() => onChange({localOnly: !localOnly})}/>
            <span className={styles.label}>Shop the World</span>
            <World/>
          </div> : <div className={classes(styles.item, styles.shopTheWorld)}/>
        }
        <div className={styles.item}>
          {<h2>{title}</h2>}
        </div>
        {!!subCategories.length &&
          <div className={classes(styles.item, styles.subCategory)}>
            <div className={classes(styles.select, selectOpen.subCategory && styles.open)}
                 onClick={() => setSelectOpen({subCategory: !selectOpen.subCategory})}>
              <ul>
                <li className={classes(!isSubCategory && styles.active)}
                    onClick={() => handleCategorySelected(category)}>
                  All
                </li>
                {subCategories.map((c) =>
                  <li key={c.id} className={classes(isSubCategory && c.slug === subCategory.slug && styles.active)}
                      onClick={() => handleCategorySelected(c)}>
                    {c.name}
                  </li>
                )}
              </ul>
              <div className={styles.selected}>
                {isSubCategory ? subCategory.name : 'Refine Category'}
                <ChevronDown/>
              </div>
            </div>
          </div>
        }
        <div className={classes(styles.item, styles.category)}>
          <div className={classes(styles.select, selectOpen.category && styles.open)}
               onClick={() => setSelectOpen({category: !selectOpen.category})}>
            <ul>
              <li
                className={classes(!category && styles.active)}
                onClick={() => {onChange({categorySlug: null})}}
              >
                All
              </li>
              {categories.map((c) =>
                <li key={c.id} className={classes(category && c.slug === category.slug && styles.active)}
                    onClick={() => handleCategorySelected(c)}>
                  {c.name}
                </li>
              )}
            </ul>
            <div className={styles.selected}>
              {category ? category.name : 'All'}
              <ChevronDown/>
            </div>
          </div>
        </div>
        {
          (orderTypes.length && (!searchText || !String(searchText).replace(/^\s+|\s+$/g,'')))?
            <div className={classes(styles.item, styles.order)}>
              <div className={classes(styles.select, selectOpen.order && styles.open)}
                   onClick={() => setSelectOpen({order: !selectOpen.order})}>
                <ul>
                  {orderTypes.map((o) =>
                    <li key={o.id} className={classes(order && o.name === order && styles.active)}
                        onClick={() => onChange({order: o.name})}>
                      {o.name}
                    </li>
                  )}
                </ul>
                <div className={styles.selected}>
                  {order && orderTypes.some(o => o.name === order) ? order : '...'}
                  <ChevronDown/>
                </div>
              </div>
            </div> :
            false
          }
      </div>
    </div>
  )
}


export default Filters