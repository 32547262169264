import React, {Component} from "react"
import RichText from 'src/components/rich_text'
import Button from 'src/components/button'
import Link from 'src/components/link'
import styles from 'src/css/slices/sign_up.module.css'
import DynamicStyle from 'src/components/dynamic_style'
import {optionalBackgroundGradient} from 'src/utilities/color'

class SignUp extends Component {
  render = () => {
    const {button_label, button_link, extra_info, extra_info_color, heading, heading_color, button_text_color, button_color, gradient_color_start, gradient_color_end} = this.props.primary
    const background = optionalBackgroundGradient(gradient_color_start, gradient_color_end)
    return (
      <div className={styles.signUp} style={{background}}>
        <RichText style={{color: heading_color}}>{heading}</RichText>
        <Link to={button_link}>
          <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: button_text_color }, background: button_color }}>
            <Button id="sign-up-component-button" inverted>{button_label}</Button>
          </DynamicStyle>
        </Link>
        <RichText style={{color: extra_info_color}} className={styles.extraInfo}>{extra_info}</RichText>
      </div>
    )
  }
}

export default SignUp