import React, { useState } from "react"
import LinkButton from 'src/components/link_button'
import styles from 'src/css/slices/hero_carousel.module.css'
import { Carousel } from 'src/components/react-responsive-carousel'
import { RichText } from 'prismic-reactjs'
import { classes } from 'src/utilities/classes'
import { coalesceRichText } from 'src/utilities/string'
import Link from 'src/components/link'
import Image from 'src/components/image'
import AppDownload from 'src/components/app_badges'

const HeroCarousel = ({ primary, fields, appBadges }) => {
  const {
    header_text,
    interval,
    swipe_tolerance,
    swipeable,
    auto_play,
    left_button_text,
    left_button_link,
    right_button_text,
    right_button_link,
    left_mobile_button_text,
    left_mobile_button_link,
    right_mobile_button_text,
    right_mobile_button_link,
    hero_image_link
  } = primary
  const carouselInterval = (interval || 5000)
  const carouselAutoPlay = (auto_play == null) ? true : auto_play
  const carouselSwipeable = (swipeable == null) ? true : swipeable
  const carouselSwipeTolerance = (swipe_tolerance || 50)

  const [backgroundIndex, setBackgroundIndex] = useState(0)

  const {
    slide_header_text,
    slide_left_button_text,
    slide_left_button_link,
    slide_right_button_text,
    slide_right_button_link,
    slide_hero_image_link
  } = (fields || [])[backgroundIndex]

  const headerText = coalesceRichText(slide_header_text,header_text)
  const heroImageLink = slide_hero_image_link || hero_image_link

  return (
    <div className={styles.root}>
      <div className={styles.carouselWrapper}>
        <Carousel
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          selectedItem={backgroundIndex}
          onChange={(index) => (backgroundIndex !== index && setBackgroundIndex(index))}
          autoPlay={carouselAutoPlay}
          swipeable={carouselSwipeable}
          interval={carouselInterval}
          swipeScrollTolerance={carouselSwipeTolerance}
          preventMovementUntilSwipeScrollTolerance={true}
        >
          {fields.map(({ background_image, background_color, footer_gradient_color, stretch_desktop_image, stretch_mobile_image, mobile_image }, i) => {
            const slideStyle = {
              backgroundColor: background_color,
              backgroundImage: `url("${background_image.url}")`,
              ...(mobile_image && { "--mobile-image": `url("${mobile_image.url}")` }),
            }
            const slideContent = (
              <div key={i} className={
                classes(
                  styles.hero,
                  stretch_mobile_image ? styles.stretchMobile : '',
                  stretch_desktop_image ? styles.stretchDesktop : '',
                  !headerText && styles.noHeaderText,
                  mobile_image && styles.withMobileImage
                )
              } style={slideStyle}>
                <div className={styles.footerShadow} style={footer_gradient_color ? { background: `linear-gradient(180deg, #00000000,${footer_gradient_color})` } : {}} />
              </div>
            )

            return heroImageLink ? <Link to={heroImageLink}>{slideContent}</Link> : slideContent

          })}
        </Carousel>
        <div className={styles.heroHeader}>
          <RichText render={headerText} />
        </div>
        <div className={styles.heroFooter}>
          <div className={styles.heroFooterInner}>
            <div className={styles.heroFooterLeft}>
              <LinkButton to={slide_left_button_link || left_button_link}>{slide_left_button_text || left_button_text}</LinkButton>
            </div>
            <div className={styles.heroFooterCenter}>
              {fields.map((f, i) =>
                <div key={i} className={i === backgroundIndex ? styles.controlDotActive : styles.controlDot} onClick={() => setBackgroundIndex(i)}>
                  <div></div>
                </div>
              )}
            </div>
            <div className={styles.heroFooterRight}>
              <LinkButton to={slide_right_button_link || right_button_link}>{slide_right_button_text || right_button_text}</LinkButton>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobileCta}>
        <div className={styles.mobileCtaActions}>
          <LinkButton to={left_mobile_button_link} className={styles.mobileCtaButton}>{left_mobile_button_text}</LinkButton>
          <LinkButton to={right_mobile_button_link} className={styles.mobileCtaButton}>{right_mobile_button_text}</LinkButton>
        </div>
        <AppDownload className={styles.appDownloads}>
          {(appBadges || []).map(({ badge, link, width, margin }, i) =>
            <Link key={i} to={link} target="_blank">
              <Image style={{ width, margin, height: 'auto' }}>{badge}</Image>
            </Link>
          )}
        </AppDownload>
      </div>
    </div>
  )
}

export default HeroCarousel