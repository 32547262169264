export const withRecaptcha = (func) => {
  return new Promise((resolve) => {
    global.grecaptcha.ready(function () {
      global.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(func).then(result => resolve(result))
    })
  })
}

export const postData = async (url = '', data = {}) => {
  return await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
}