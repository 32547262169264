import { graphql } from "gatsby"
export const Link = graphql`
  fragment Link on PRISMIC__Linkable {
    ... on PRISMIC__Document {
      _meta {
        type
        uid
        lang
      }
    }
    ... on PRISMIC__ExternalLink {
      url
      target
    }
  }
`