import React from "react"
import Image from 'src/components/image'
import Link from 'src/components/link'
import { RichText } from 'prismic-reactjs'
import styles from 'src/css/slices/agencies.module.css'

const Agencies = ({ primary, fields }) => {
  const { heading } = primary
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <RichText render={heading} />
        <div className={styles.partnerContainer}>
          {fields.map(({ image, link }, i) => <div className={styles.partner} key={i}>
            <Link to={link} target="_blank">
              <Image className={styles.image} objectFit="contain">{image}</Image>
            </Link>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default Agencies