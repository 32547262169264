import React from 'react'
import DynamicStyle from './dynamic_style'
import { optionalThreeColorGradient } from "src/utilities/color"

export default function GlobalButtonStyle({siteSettings}) {
  const { button_color, button_hover_color_1, button_hover_color_2, button_hover_color_3, button_text_color, button_text_hover_color } = (siteSettings || {})
  const hoverBackground = optionalThreeColorGradient(button_hover_color_1, button_hover_color_2, button_hover_color_3)
  return <DynamicStyle className="global-button-style" dynamicStyle={{
    color: button_text_color,
    backgroundColor: button_color,
    '&.global-button-style-inverted': {
      color: button_color || '#786dff',
      backgroundColor: button_text_color || 'white',
    },
    '&.global-button-style-inverted:hover': {
      color: button_text_hover_color || 'white'
    },
    '&:hover': {
      color: button_text_hover_color
    },
    '&::before': {
      background: hoverBackground
    }
  }} />
}