import React from 'react'
import styles from '../styles/menu.module.css'
import {classes} from '../utils/classes'

const Menu = ({categories, category, order, orderTypes, onChange}) => {
  const handleClick = (item, order = undefined) => e => {
    if (item && item.externalUrl) {
      window.open(item.externalUrl, '_blank')
    } else {
      onChange(
        item ?
          {categorySlug: item.slug} :
          {categorySlug: null, searchText: undefined, ...(order !== undefined && { order })}
      )
    }
    e.stopPropagation()
  }

  const orderTypeNames = (orderTypes || []).map(ot => ot.order_type_name)
  return (
    <nav className={styles.categoryMenu}>
      <ul>
        <li className={classes((!category || !category.parentId) && !orderTypeNames.includes(order) && styles.active)}
            onClick={handleClick(null, null)}>
          All
        </li>
        {
          orderTypeNames.map(orderTypeName =>
            <li className={classes((!category || !category.parentId) && order === orderTypeName && styles.active)}
                onClick={handleClick(null, orderTypeName)} key={`Ordering_${orderTypeName}`}>
              {orderTypeName}
            </li>
          )
        }
        {categories.map((item, i) =>
          <li key={i} className={classes((item.id === category.id || (category.parent && item.id === category.parent.id)) && styles.active)}
              onClick={handleClick(item)}>
            {item.name}
            {item.children && !!item.children.length &&
            <ul>
              {item.children.map((subItem, i) =>
                <li key={i} className={classes(subItem.id === category.id && styles.active)}
                    onClick={handleClick(subItem)}>
                  {subItem.name}
                </li>
              )}
              <li className={styles.allItem} onClick={handleClick(item)}>View All</li>
            </ul>
            }
          </li>
        )}
      </ul>
    </nav>
  )
}


export default Menu