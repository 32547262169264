import React, { Component } from 'react'
import Link from 'src/components/link'
import Image from 'src/components/image'
import PageContext from 'src/components/page_context'
import SiteSettingsContext from 'src/components/site_settings_context'
import LocaleSwitcher from 'src/components/locale_switcher'
import styles from 'src/css/layout.module.css'
import LaybuyMono from 'src/images/laybuy_mono'
import Button from 'src/components/button'
import { enableScroll } from 'src/utilities/scrolling'
import { localeName } from 'src/utilities/locale'
import Location from 'src/images/location'
import ChevronDown from 'src/images/chevron_down'
import ArrowRight from 'src/images/arrow_right'
import Tick from 'src/images/tick'
import { Facebook, Instagram, LinkedIn, Twitter } from 'src/images/social'
import { classes } from 'src/utilities/classes'
import { alias } from 'src/utilities/locale'
import SiteBanner from './site_banner'
import GlobalButtonStyle from './global_button_style'
import DynamicStyle from 'src/components/dynamic_style'
import NavMenu, {NavItem} from './nav_menu'
import AppBadges from './app_badges'

export default class Layout extends Component {

  state = { localeOpen: false, subscribeAddress: '', siteBannerIndex: 0 }

  constructor(props){
    super(props)
    this.navRef = React.createRef();
  }

  componentWillUnmount = () => {
    enableScroll()
    document.body.className = ''
  }

  get shouldShowLanguagePrompt() {
    return !this.state.languagePromptDismissed && this.state.userLang && this.state.userLang !== this.props.pageContext.lang
  }

  get languages() {
    const { alternateLanguages, type, uid, lang } = this.props.pageContext
    const currentLang = { type, uid, lang }
    const allLangs = [...alternateLanguages || [], currentLang]
    return allLangs.sort((a, b) => localeName(a.lang).localeCompare(localeName(b.lang)))
  }

  handleDismissLanguagePrompt = () =>
    this.setState({ languagePromptDismissed: true })

  linksOpen = name => this.state.openLinkSection === name

  handleOpenLinks = name => () => {
    this.setState({ openLinkSection: this.state.openLinkSection === name ? '' : name })
  }

  render = () => {
    const { children, pageContext, menuData={}, siteBannerData, siteSettings, home, invertedFooter } = this.props
    const {
      body: footerLinkSections,
      social_links: socialLinks,
      footer_social_link_color,
      log_in_link_text,
      log_in_link_text_color,
      log_in_link_text_color_menu,
      log_in_link_text_color_home,
      personal_log_in_link_text,
      personal_log_in_link,
      merchant_log_in_link_text,
      merchant_log_in_link,
      sign_up_link_text,
      personal_sign_up_link_text,
      personal_sign_up_link,
      merchant_sign_up_link_text,
      merchant_sign_up_link,
      sign_up_button_color,
      sign_up_button_text_color,
      sign_up_button_color_home,
      sign_up_button_text_color_home,
      sign_up_button_color_menu,
      sign_up_button_text_color_menu,
      get_the_app_header,
      app_badges
    } = menuData

    return (
      <PageContext.Provider value={pageContext}>
        <SiteSettingsContext.Provider value={siteSettings}>
          <GlobalButtonStyle siteSettings={siteSettings} />
          <DynamicStyle className="nav-login-link" dynamicStyle={{
            '&&': {
              color: log_in_link_text_color,
              '& .transparent': {
                color: log_in_link_text_color_home
              },
              '& .expanded': {
                color: log_in_link_text_color_menu
              }
            }
          }} />
          <DynamicStyle className="nav-signup-link" dynamicStyle={{
            '&&': {
              color: sign_up_button_text_color,
              background: sign_up_button_color,
              '& .transparent': {
                color: sign_up_button_text_color_home,
                background: sign_up_button_color_home,
              },
              '& .expanded': {
                color: sign_up_button_text_color_menu,
                background: sign_up_button_color_menu,
              }
            }
          }} />

          <NavMenu
            transparent={home}
            siteBanner={<SiteBanner siteBannerData={siteBannerData} />}
            logoLink={<Link to={{type: 'homepage'}} />}
            login={
              <NavItem label={log_in_link_text} className="nav-login-link">
                {personal_log_in_link_text && <NavItem component={Link} label={personal_log_in_link_text} to={personal_log_in_link} arrow />}
                {merchant_log_in_link_text && <NavItem component={Link} label={merchant_log_in_link_text} to={merchant_log_in_link} arrow />}
              </NavItem>
            }
            signup={
              <NavItem button label={sign_up_link_text} className="nav-signup-link">
                {personal_sign_up_link_text && <NavItem component={Link} label={personal_sign_up_link_text} to={personal_sign_up_link} arrow />}
                {merchant_sign_up_link_text && <NavItem component={Link} label={merchant_sign_up_link_text} to={merchant_sign_up_link} arrow />}
              </NavItem>
            }
            mobileMenuFooter={
              <AppBadges atBottom>
                {(app_badges || []).map(({ badge, link, width, margin }, i) =>
                  <Link key={i} to={link} target="_blank">
                    <Image style={{ width, margin, height: 'auto' }}>{badge}</Image>
                  </Link>
                )}
              </AppBadges>
            }
          >
            <NavItem component={Link} label="Shop" to={{ type: 'shop_director' }} />
            <NavItem component={Link} label="How it Works" to={{ type: 'how_it_works' }} />
            <NavItem component={Link} label="Get the App" to={"https://lyby.me/GetTheApp"} />
            <NavItem component={Link} label="For Merchants" to={{ type: 'our_merchants' }} arrow />
          </NavMenu>

          <div className={styles.content} id='content'>
            {children}
          </div>

          <div className={classes(styles.footerWrapper, invertedFooter && styles.inverted)}>
            <a className={styles.backToTop} href='#top'><ArrowRight /></a>
            <div className={styles.footer}>
              <div className={styles.footerLinks}>
                {
                  (footerLinkSections && footerLinkSections.length > 0 && footerLinkSections[0].fields.length > 0) ? footerLinkSections.map(section => {
                    const { primary: { section_name: sectionName }, fields: footerLinks = [] } = (section || {})
                    return (
                      <div className={classes(this.linksOpen(sectionName) && styles.active)} key={sectionName}>
                        <h4 onClick={this.handleOpenLinks(sectionName)}>{sectionName}</h4>
                        <ul>
                          {
                            footerLinks.map(({ link, link_text: linkText }) =>
                              <li key={linkText}>
                                <Link to={link}>{linkText}</Link>
                              </li>
                            )
                          }
                        </ul>
                      </div>
                    )
                  }) : <>
                    <div className={classes(this.linksOpen('company') && styles.active)}>
                      <h4 onClick={this.handleOpenLinks('company')}>Company</h4>
                      <ul>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'about' }}>About</Link>
                        </li>
                        <li>
                          <Link to={`https://help.laybuy.com/`}>Contact</Link>
                        </li>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'consumer-terms' }}>Consumer Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'terms' }}>Website Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'privacy' }}>Privacy</Link>
                        </li>
                      </ul>
                    </div>
                    <div className={classes(this.linksOpen('more') && styles.active)}>
                      <h4 onClick={this.handleOpenLinks('more')}>More from Laybuy</h4>
                      <ul>
                        <li>
                          <Link to={`https://help.laybuy.com/`}>Help & Support</Link>
                        </li>
                        <li>
                          <Link to={`https://help.laybuy.com/`}>FAQ</Link>
                        </li>
                        <li>
                          <Link to={`https://pages.laybuy.com/media-enquiry`}>Media</Link>
                        </li>
                        <li>
                          <Link to={`https://pages.laybuy.com/success`}>Blog</Link>
                        </li>
                        <li>
                          <Link to={`https://pages.laybuy.com/competitions`}>Competitions</Link>
                        </li>
                        <li>
                          <Link to={`https://pages.laybuy.com/${alias(pageContext.lang)}/responsible-spending`}>Responsible Spending</Link>
                        </li>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'unforeseen-financial-hardship' }}>Financial Hardship</Link>
                        </li>
                      </ul>
                    </div>
                    <div className={classes(this.linksOpen('resources') && styles.active)}>
                      <h4 onClick={this.handleOpenLinks('resources')}>Resources</h4>
                      <ul>
                        <li>
                          <Link to={process.env.GATSBY_MERCHANT_URL || 'https://www.laybuy.com/merchant'}>Merchant Dashboard</Link>
                        </li>
                        <li>
                          <Link to='https://docs.laybuy.com'>Developer Documentation</Link>
                        </li>
                      </ul>
                    </div>
                  </>
                }
                <div className={styles.newsletter}>
                  {app_badges && app_badges.length > 0 && app_badges[0].badge &&
                    <div className={styles.getTheApp}>
                      <h4>{get_the_app_header || "Get the App"}</h4>
                      <div className={styles.appBadges}>
                        {(app_badges || []).map(({ badge, link, width, margin }, i) =>
                          <Link to={link} target="_blank" className={styles.appBadge}>
                            <Image style={{ width, margin, height: 'auto' }}>{badge}</Image>
                          </Link>
                        )}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className={styles.lower}>
                <div className={styles.footerLogo}>
                  <span className={styles.logoWrapper}><LaybuyMono /></span>
                </div>
                <div className={styles.footerLocale}
                  onClick={() => this.setState({ localeOpen: !this.state.localeOpen })}>
                  <div className={classes(styles.localeSelect, this.state.localeOpen && styles.open)}>
                    <ul>
                      {/* Filtering localeName(page.lang) !== page.lang) prevents un-configured locales from showing in the dropdown */}
                      {this.languages.filter(page => localeName(page.lang) !== page.lang).map((page, idx) =>
                        <Link to={page} key={idx}>
                          <li key={page.lang} className={classes(page.lang === pageContext.lang && styles.active)}>
                            {localeName(page.lang)}
                            {page.lang === pageContext.lang && <Tick />}
                          </li>
                        </Link>
                      )}
                    </ul>
                    <div className={styles.selected}>
                      <Location />
                      {localeName(pageContext.lang)}
                      <ChevronDown />
                    </div>
                  </div>
                </div>
                <div className={styles.footerSocial}>
                  <ul>
                    {(socialLinks && socialLinks.length) ? <>
                      {socialLinks.map(({ link, link_icon }, i) =>
                        <li key={i}>
                          <Link target='_blank' rel="noopener noreferrer" to={link}>
                            <Button style={{ color: (footer_social_link_color || "#786dff") }}>
                              <Image inlineSVG>{link_icon}</Image>
                            </Button>
                          </Link>
                        </li>
                      )}
                    </> : <>
                      <li>
                        <a href='https://www.facebook.com/PaybyLaybuy/' target='_blank' rel="noopener noreferrer">
                          <Button inverted><Facebook /></Button>
                        </a>
                      </li>
                      <li>
                        <a href='https://www.instagram.com/laybuy.nz/' target='_blank' rel="noopener noreferrer">
                          <Button inverted><Instagram /></Button>
                        </a>
                      </li>
                      <li>
                        <a href='https://www.linkedin.com/company/laybuy?report%2Esuccess=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf' target='_blank' rel="noopener noreferrer">
                          <Button inverted><LinkedIn /></Button>
                        </a>
                      </li>
                      <li>
                        <a href='https://twitter.com/PaybyLaybuy' target='_blank' rel="noopener noreferrer">
                          <Button inverted><Twitter /></Button>
                        </a>
                      </li>
                    </>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <LocaleSwitcher />
        </SiteSettingsContext.Provider>
      </PageContext.Provider>
    )
  }
}