import React from "react"
import LinkButton from 'src/components/link_button'
import { RichText } from 'prismic-reactjs'
import styles from 'src/css/slices/join.module.css'

const Join = ({ primary, fields }) => {
  const { heading, partner_button_text, partner_button_link, merchant_button_text, merchant_button_link } = primary

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <RichText render={heading} />
        <div className={styles.buttons}>
          {partner_button_text && <LinkButton to={partner_button_link} className={styles.button}>{partner_button_text}</LinkButton>}
          {merchant_button_text && <LinkButton to={merchant_button_link} className={styles.button}>{merchant_button_text}</LinkButton>}
        </div>
      </div>
    </div>
  )
}

export default Join