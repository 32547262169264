export const getPageData = renderer => data => {
  const {data: {prismic}} = data
  const pageKey = Object.keys(prismic).find(k => (k.startsWith('all') && !k.startsWith('allMenus') && !k.startsWith('allSite_banners') && !k.startsWith('allSite_settingss')))
  const {
    [pageKey]: {edges: [{node: pageData}={}] },
    allMenus: {edges: [{node: menuData}={}] },
    allSite_banners: {edges: [{node: siteBannerData}={}] },
    allSite_settingss: {edges: [{node: siteSettings}={}] }
  } = prismic
  return pageData ? renderer({...data, pageData, menuData, siteBannerData, siteSettings}) : null
}