import { graphql } from "gatsby"
export const Meta = graphql`
  fragment Meta on PRISMIC_Meta {
    alternateLanguages {
      lang
      type
      uid
    }
    lang
    type
    uid
  }
`