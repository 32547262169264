import React, { useState } from "react"
import styles from 'src/css/slices/info_carousel.module.css'
import Image from 'src/components/image'
import { Carousel } from 'src/components/react-responsive-carousel';
import {classes} from 'src/utilities/classes'
import LinkButton from 'src/components/link_button'
import Link from 'src/components/link'
import { RichText } from 'prismic-reactjs'
import {linkResolver} from 'src/utilities/linkResolver'

const InfoCarousel = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const { control_dot_color, control_dot_selected_color } = fields[currentIndex]
  const renderIndicator = (clickHandler, isSelected, index, label) =>
    <li
      className={classes(styles.controlDot, isSelected && styles.selected)}
      style={{borderColor: control_dot_selected_color}}
      onClick={clickHandler}
    >
      <div style={{backgroundColor: (isSelected ? control_dot_selected_color : control_dot_color)}}></div>
    </li>

  return (
    <Carousel className={styles.carousel}
      showIndicators={(fields.length > 1)}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={(fields.length > 1)}
      style={{ backgroundColor: 'white' }}
      swipeScrollTolerance={50}
      preventMovementUntilSwipeScrollTolerance={true}
      renderIndicator={renderIndicator}
      onChange={i => setCurrentIndex(i)}
    >
      {fields.map(({ button_image, button_text, button_link, desktop_image, mobile_image, background_color: backgroundColor, text_color, header_text, text, display_mode }, i) =>
        <div className={classes(styles.wrapper, display_mode === 'Wide' && styles.wide)} style={{backgroundColor}} key={i}>
          <div className={styles.left}>
            <div className={styles.details} style={{color: text_color}}>
              <RichText render={header_text} linkResolver={linkResolver}/>
              <RichText render={text} linkResolver={linkResolver}/>
              {!button_text && <Link to={button_link}>
                <Image className={styles.linkImage} loading="lazy">{button_image}</Image>
              </Link>}
              {button_text && <LinkButton to={button_link}>{button_text}</LinkButton>}
            </div>
          </div>
          <div className={styles.right}>
            <Image className={styles.desktopImage} loading="lazy">{desktop_image}</Image>
            <Image className={styles.mobileImage} loading="lazy">{mobile_image || desktop_image}</Image>
          </div>
        </div>
      )}
    </Carousel>

  )
}

export default InfoCarousel