import React from "react"
import styles from 'src/css/slices/four_features.module.css'
import RichText from 'src/components/rich_text'

const Features = ({ fields }) => {
  return (
    <div className={styles.root}>
      <ul className={styles.features}>
        {fields.map(({icon, title, description}, i) => (
          <li className={styles.feature}>
            <img src={icon.url} alt={icon.alt} />
            <h3 className={styles.title}>{title}</h3>
            <RichText className={styles.description}>{description}</RichText>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Features