import React, {Component} from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import Button from 'src/components/button'
import Link from 'src/components/link'
import DynamicStyle from 'src/components/dynamic_style'
import styles from 'src/css/slices/image_with_text_floating.module.css'
import {classes} from 'src/utilities/classes'
import {optionalBackgroundGradient} from 'src/utilities/color'

class ImageWithTextFloating extends Component {
  get buttonId() {
    return `image-with-text-button-${this.props.sliceIndex}`
  }

  render = () => {
    const {feature_image, feature_image_mobile, text, text_color, button_label, button_link, button_color, button_text_color, gradient_color_start, gradient_color_end, image_position_right, full_width} = this.props.primary
    const background = optionalBackgroundGradient(gradient_color_start, gradient_color_end)
    const textColor = (text_color || (!!background ? 'white' : 'black'))
    return (
      <div className={styles.wrapper} style={full_width ? { background } : { paddingTop: 0, paddingBottom: 0 }}>
        <div className={styles.imageWithText} style={full_width ? {} : { background, overflow: 'hidden', padding: '0 3%' }}>
          <div className={classes(styles.inner,!image_position_right && styles.reverse)}>
            <div className={classes(styles.featureImage,!image_position_right && styles.reverse)}>
              <Image className={!!feature_image_mobile ? styles.featureImageDesktop : undefined} dpiRatio={2}>{feature_image}</Image>
              {!!feature_image_mobile && <Image className={styles.featureImageMobile} dpiRatio={2}>{feature_image_mobile}</Image>}
            </div>
            <div className={styles.content} style={{padding: '0 0'}}>
              <RichText className={styles.description} style={{color: textColor}}>{text}</RichText>
              {button_label && <Link to={button_link}>
                <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: button_text_color }, background: button_color }}>
                  <Button id={this.buttonId} withArrow inverted={!!background}>{button_label}</Button>
                </DynamicStyle>
              </Link>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImageWithTextFloating