import React from "react"
import LinkButton from 'src/components/link_button'
import Image from 'src/components/image'
import { RichText } from 'prismic-reactjs'
import styles from 'src/css/slices/integration_partners.module.css'

const IntegrationPartners = ({ primary, fields }) => {
  const { default_button_text, heading } = primary
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <RichText render={heading} />
        <div className={styles.partnerContainer}>
          {fields.map(({ image, description, button_text, button_link }, i) => <div className={styles.partner} key={i}>
            <Image className={styles.image} objectFit="contain">{image}</Image>
            <div className={styles.description}>
              <RichText render={description} />
            </div>
            <LinkButton to={button_link} className={styles.button} target="_blank">
              {button_text || default_button_text || 'Visit website'}
            </LinkButton>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default IntegrationPartners