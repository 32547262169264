import React from "react"
import styles from 'src/css/slices/dual_promo.module.css'
import AdaptiveImage from 'src/components/adaptive_image'
import Link from 'src/components/link'

const DualPromo = ({ primary }) => {
  const {
    background_color: backgroundColor,
    desktop_image_left,
    desktop_image_right,
    mobile_image_left,
    mobile_image_right,
    link_left,
    link_right,
  } = primary

  return (
    <div className={styles.wrapper} style={{ backgroundColor }}>
      <div className={styles.inner}>
        <Link to={link_left} className={styles.imageLink}>
          <AdaptiveImage breakpoint='600px' desktop={desktop_image_left} mobile={mobile_image_left} />
        </Link>
        <Link to={link_right} className={styles.imageLink}>
          <AdaptiveImage breakpoint='600px' desktop={desktop_image_right} mobile={mobile_image_right} />
        </Link>
      </div>
    </div>
  )
}

export default DualPromo