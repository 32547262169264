import {fetchData} from '../utils/JSONApiUtils'

export const getCategory = async name => {
  return fetchData(`categories/${name}`, {include: 'children.children,orderTypes,children.orderTypes,children.children.orderTypes'})
}

export const getOrders = async() => {
  return fetchData('order_types')
}

export const getTiles = async({page, categoryId, filter, cached = 'false'}) => {
  const options = {
    page,
    include: 'activePromotion',
    filter: {
      ...filter,
      category_id: categoryId,
      source: 'web',
    },
    cached
  }
  global.dataLayer.push({ 'event':'ShopHereSearch', 'query': options })
  return fetchData('tiles', options)
}