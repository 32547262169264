import { graphql } from "gatsby"
export const SiteBanner = graphql`
  fragment SiteBanner on PRISMIC_Site_bannerConnectionConnection {
    edges {
      node {
        rotation_frequency_seconds
        can_be_closed
        banners {
          text_color
          background_color
          message
        }
        _meta {
          lang
        }
      }
    }
  }
`