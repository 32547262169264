import React, { useState } from 'react'
import CloseModal from 'src/images/close_modal'
import Button from 'src/components/button'
import styles from '../styles/tile_modal.module.css'
import tileStyles from '../styles/tile.module.css'

export const PromoModalContent = ({ tile, tileImage, activePromotion, onCloseModal, siteSettings }) => {
  const [copied, setCopied] = useState(false)
  const copyCode = (e) => {
    navigator.clipboard.writeText(activePromotion.code)
    e.preventDefault()
    e.stopPropagation()
    setCopied(true)
  }

  const {
    promo_code_header_text,
    promo_copy_code_button_text,
    promo_copied_code_button_text,
    promo_start_shopping_button_text,
  } = (siteSettings || {})

  return <div className={styles.popup}>
    <div className={tileStyles.imgWrapper}>
      <button className={styles.close} onClick={onCloseModal}>
        <CloseModal />
      </button>
      {tileImage}
    </div>
    <div className={styles.tileName}>
      {tile.name}
    </div>
    <h2 className={styles.promoTitle}>{activePromotion.title}</h2>
    <p className={styles.promoBody}>{activePromotion.body}</p>
    {activePromotion.code && <p>
      <span className={styles.promoCodeLabel}>{promo_code_header_text || 'Use code:'}</span>
      <br />
      <span className={styles.promoCode}>{activePromotion.code}</span>
    </p>}
    <div className={styles.buttons}>
      {activePromotion.code && <Button inverted className={styles.inverted} onClick={copyCode}>{copied ? (promo_copied_code_button_text || 'Copied!') : (promo_copy_code_button_text || 'Copy Code')}</Button>}
      <a target="_blank" rel="noopener noreferrer" href={activePromotion.currentUrl}><Button>{promo_start_shopping_button_text || 'Start Shopping'}</Button></a>
    </div>
    <div className={styles.promoFooter}>{activePromotion.footer}</div>
  </div>
}