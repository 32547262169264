import React from 'react'

export default function Tick() {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
      <path id="svg_1" d="m1.55006,6c0,2.37623 1.92572,4.30179 4.30178,4.30179c2.37623,0 4.30179,-1.92572 4.30179,-4.30179c0,-2.37622 -1.92572,-4.30178 -4.30179,-4.30178c-2.37622,0 -4.30178,1.92572 -4.30178,4.30178zm-1.22908,0c0,-3.05461 2.47558,-5.53086 5.53086,-5.53086c3.05462,0 5.53087,2.47558 5.53087,5.53086c0,3.05462 -2.47558,5.53087 -5.53087,5.53087c-3.05461,0 -5.53086,-2.47558 -5.53086,-5.53087z" />
      <path id="Path" d="m4.7168,7.91848c0.24323,0.20747 0.56752,0.20747 0.81075,0l2.91867,-2.98758c0.52698,-0.53942 -0.28376,-1.3693 -0.81074,-0.82988l-2.5133,2.61413l-1.05397,-1.12034c-0.52698,-0.53942 -1.33773,0.29046 -0.81074,0.82988l1.45933,1.49379z" />
    </svg>
  )
}
