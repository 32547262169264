import React, {Component} from "react"
import RichText from 'src/components/rich_text'
import Button from 'src/components/button'
import Link from 'src/components/link'
import styles from 'src/css/slices/button_banner.module.css'
import DynamicStyle from 'src/components/dynamic_style'

class ButtonBanner extends Component {
  get buttonId() {
    return `button-banner-button-${this.props.sliceIndex}`
  }

  render = () => {
    const {button_label, button_link, extra_info, extra_info_color, heading, heading_color, gradient_color_start, gradient_color_end, button_text_color, button_color} = this.props.primary
    const gradientColorStart = gradient_color_start || '#786dff'
    const gradientColorEnd = gradient_color_end || gradient_color_start || '#ecc3e7'
    const background = `linear-gradient(50deg,${gradientColorStart},${gradientColorEnd})`
    return (
      <div className={styles.buttonBanner} style={{ background }}>
        <RichText style={{color: heading_color}}>{heading}</RichText>
        <Link to={button_link}>
          <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: button_text_color }, background: button_color }}>
            <Button inverted id={this.buttonId}>{button_label}</Button>
          </DynamicStyle>
        </Link>
        <RichText className={styles.extraInfo} style={{color: extra_info_color}}>{extra_info}</RichText>
      </div>
    )
  }
}

export default ButtonBanner