import React from 'react'
import styles from 'src/css/link_button.module.css'
import {classes} from 'src/utilities/classes'
import ArrowRightNarrow from 'src/images/arrow_right_narrow'
import Link from 'src/components/link'

export default function LinkButton({children, to, target, className, size = 'md', ...props}) {
    return children ? (
      <Link to={to} target={target} className={styles.link}>
        <button {...props} className={classes(className, styles.linkButton, styles[`${size}Size`])}>
          <span>{children}</span> <ArrowRightNarrow />
        </button>
      </Link>
    ) : null
}