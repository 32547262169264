import React from "react"
import styles from 'src/css/components/checkbox.module.css'

const Checkbox = ({ id, onChange, checked, label, style }) => {
  const toggleChecked = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onChange && onChange({ target: { checked: !checked } })
  }
  return (
    <div className={styles.checkbox} onClick={toggleChecked} style={style}>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} />
      {checked ? <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1 7.4C1 5.15979 1 4.03969 1.43597 3.18404C1.81947 2.43139 2.43139 1.81947 3.18404 1.43597C4.03969 1 5.15979 1 7.4 1H18.6C20.8402 1 21.9603 1 22.816 1.43597C23.5686 1.81947 24.1805 2.43139 24.564 3.18404C25 4.03969 25 5.15979 25 7.4V18.6C25 20.8402 25 21.9603 24.564 22.816C24.1805 23.5686 23.5686 24.1805 22.816 24.564C21.9603 25 20.8402 25 18.6 25H7.4C5.15979 25 4.03969 25 3.18404 24.564C2.43139 24.1805 1.81947 23.5686 1.43597 22.816C1 21.9603 1 20.8402 1 18.6V7.4Z" fill="#786DFF" stroke="#786DFF" strokeWidth="2" />
        <path d="M5 13L11 18L20 6" stroke="white" strokeWidth="4" />
      </svg>
        : <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1 7.4C1 5.15979 1 4.03969 1.43597 3.18404C1.81947 2.43139 2.43139 1.81947 3.18404 1.43597C4.03969 1 5.15979 1 7.4 1H18.6C20.8402 1 21.9603 1 22.816 1.43597C23.5686 1.81947 24.1805 2.43139 24.564 3.18404C25 4.03969 25 5.15979 25 7.4V18.6C25 20.8402 25 21.9603 24.564 22.816C24.1805 23.5686 23.5686 24.1805 22.816 24.564C21.9603 25 20.8402 25 18.6 25H7.4C5.15979 25 4.03969 25 3.18404 24.564C2.43139 24.1805 1.81947 23.5686 1.43597 22.816C1 21.9603 1 20.8402 1 18.6V7.4Z" fill="white" stroke="#C4C2D1" strokeWidth="2" />
        </svg>
      }
      {label}
    </div>
  )
}

export default Checkbox