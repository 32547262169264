import { graphql } from "gatsby"
export const Partners = graphql`
  fragment Partners on PRISMIC_PartnersConnectionConnection {
    edges {
      node {
        _meta {
          ...Meta
        }
        ...PartnersSEO
        body {
          ... on PRISMIC_PartnersBodyBecome_a_partner {
            type
            label
            primary {
              heading
              description
              button_text
              button_link { ...Link }
              interval
              auto_play
              swipeable
              swipe_tolerance
            }
            fields {
              desktop_image
              mobile_image
            }
          }
          ... on PRISMIC_PartnersBodyFeatured_partners {
            type
            label
            primary {
              heading
              default_button_text
            }
            fields {
              image
              description
              button_text
              button_link { ...Link }
            }
          }
          ... on PRISMIC_PartnersBodyIntegration_partners {
            type
            label
            primary {
              heading
              default_button_text
            }
            fields {
              image
              description
              button_text
              button_link { ...Link }
            }
          }
          ... on PRISMIC_PartnersBodyAgencies {
            type
            label
            primary {
              heading
            }
            fields {
              image
              link { ...Link }
            }
          }
          ... on PRISMIC_PartnersBodyJoin {
            type
            label
            primary {
              heading
              partner_button_text
              partner_button_link { ...Link }
              merchant_button_text
              merchant_button_link { ...Link }
            }
          }
        }
      }
    }
  }
`