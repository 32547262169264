import { graphql } from "gatsby"

export const InfoPageSEO = graphql`
  fragment InfoPageSEO on PRISMIC_Info_page {
    meta_title
    meta_description
    canonical_link {
      ...Link
    }
    alternate_links {
      link {
        ...Link
      }
      hreflang
    }
    meta_tags {
      meta_name
      meta_content
      http_equiv
    }
    json_ld_schema
  }
`

export const HomePageSEO = graphql`
  fragment HomePageSEO on PRISMIC_Homepage_15 {
    meta_title
    meta_description
    canonical_link {
      ...Link
    }
    alternate_links {
      link {
        ...Link
      }
      hreflang
    }
    meta_tags {
      meta_name
      meta_content
      http_equiv
    }
    json_ld_schema
  }
`

export const HowItWorksSEO = graphql`
  fragment HowItWorksSEO on PRISMIC_How_it_works_15 {
    meta_title
    meta_description
    canonical_link {
      ...Link
    }
    alternate_links {
      link {
        ...Link
      }
      hreflang
    }
    meta_tags {
      meta_name
      meta_content
      http_equiv
    }
    json_ld_schema
  }
`

export const OurMerchantsSEO = graphql`
  fragment OurMerchantsSEO on PRISMIC_For_merchants_15 {
    meta_title
    meta_description
    canonical_link {
      ...Link
    }
    alternate_links {
      link {
        ...Link
      }
      hreflang
    }
    meta_tags {
      meta_name
      meta_content
      http_equiv
    }
    json_ld_schema
  }
`

export const PartnersSEO = graphql`
  fragment PartnersSEO on PRISMIC_Partners {
    meta_title
    meta_description
    canonical_link {
      ...Link
    }
    alternate_links {
      link {
        ...Link
      }
      hreflang
    }
    meta_tags {
      meta_name
      meta_content
      http_equiv
    }
    json_ld_schema
  }
`

export const ShopDirectorySEO = graphql`
  fragment ShopDirectorySEO on PRISMIC_Shop_director {
    meta_title
    meta_description
    canonical_link {
      ...Link
    }
    alternate_links {
      link {
        ...Link
      }
      hreflang
    }
    meta_tags {
      meta_name
      meta_content
      http_equiv
    }
    json_ld_schema
  }
`

export const LandingPageSEO = graphql`
  fragment LandingPageSEO on PRISMIC_Landing_page {
    meta_title
    meta_description
    canonical_link {
      ...Link
    }
    alternate_links {
      link {
        ...Link
      }
      hreflang
    }
    meta_tags {
      meta_name
      meta_content
      http_equiv
    }
    json_ld_schema
  }
`