import React, {useState, useRef, useEffect} from 'react'
import styles from 'src/css/components/cross_fade.module.css'
import {classes} from 'src/utilities/classes'

export const CrossFadeItem = (props) => {
  const {_active, _prev, name, className, children, ...other} = props
  return (
    <div className={classes(styles.item, _active && styles.active, _prev && styles.prev, className)} {...other}>
      {children}
    </div>
  )
}

const CrossFade = (props) => {
  const {children, className, active, ...other} = props
  const activeRef = useRef(active)
  const [prev, setPrev] = useState(null)

  useEffect(() => {
    setPrev(activeRef.current)
    activeRef.current = active
  }, [active])

  return (
    <div className={classes(styles.root, className)} {...other}>
      {React.Children.map(children, (child) => (
        (child.props?.name === active && React.cloneElement(child, {_active: true})) ||
        (child.props?.name === prev && React.cloneElement(child, {_prev: true})) ||
        child
      ))}
    </div>
  )
}

export default CrossFade
