import React from 'react'
import CloseModal from 'src/images/close_modal'
import PageContext from 'src/components/page_context'
import styles from '../styles/affiliate_modal.module.css'
import { RichText } from 'prismic-reactjs'

export const AffiliateModalContent = ({ tile, onCloseModal, siteSettings }) => {
  const {
    affiliate_overlay_text,
    affiliate_overlay_gradient,
    affiliate_popup_text,
    affiliate_proceed_to_text,
    affiliate_app_qr_code,
    affiliate_app_store_button,
    affiliate_google_play_button,
    affiliate_app_store_link,
    affiliate_google_play_link,
    affiliate_app_logo,
  } = (siteSettings || {})

  const affiliatePopupText = (affiliate_popup_text || []).map(el => ({
    ...el, ...(!!el.text && { text: el.text.replace('{{tile.name}}', tile.name) })
  }))

  return <PageContext.Consumer>
    {({ lang }) =>
      <div className={styles.container}>
        <button className={styles.close} onClick={onCloseModal}>
          <CloseModal />
        </button>
        <div className={styles.image} style={{ backgroundImage: [affiliate_overlay_gradient, `url('${tile.currentAffiliateImageUrl}')`].filter(x => !!x).join(', ') }}>
          <div style={{ fontSize: '50px', lineHeight: '43px', fontWeight: 'bold', color: 'white' }}>
            {affiliate_overlay_text}
          </div>
          <div className={styles.logos}>
            <img className={styles.appLogo} src={affiliate_app_logo?.url} alt={affiliate_app_logo?.alt} />
            <div className={styles.divider}></div>
            <div className={styles.affiliateLogo}>
              <img src={tile.currentLogoUrl} alt={`${tile.name} Logo`} />
            </div>
          </div>
        </div>
        <div className={styles.text}>
          <RichText render={affiliatePopupText} />
        </div>
        <div className={styles.appLinks}>
          <div className={styles.storeBadges}>
            <a href={affiliate_app_store_link?.url} target="_blank" rel="noopener noreferrer">
              <img src={affiliate_app_store_button?.url} alt={affiliate_app_store_button?.alt} />
            </a>
            <a href={affiliate_google_play_link?.url} target="_blank" rel="noopener noreferrer">
              <img src={affiliate_google_play_button?.url} alt={affiliate_google_play_button?.alt} />
            </a>
          </div>
          <div className={styles.qrCode}>
            <img src={affiliate_app_qr_code?.url} alt={affiliate_app_qr_code?.alt} />
          </div>
        </div>
        <div className={styles.proceedTo}>
          <a href={tile.currentUrl} target="_blank" rel="noopener noreferrer">{affiliate_proceed_to_text} {tile.name}</a>
        </div>
      </div>
    }
  </PageContext.Consumer>
}