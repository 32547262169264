import { graphql } from "gatsby"
export const NotFound = graphql`
  fragment NotFound on PRISMIC_Not_foundConnectionConnection {
    edges {
      node {
        _meta {
          ...Meta
        }
        body {
          ... on PRISMIC_Not_foundBodyFull_width_promo {
            type
            label
            primary {
              promo_image
              promo_image_mobile
              promo_url {
                ...Link
              }
            }
          }
          ... on PRISMIC_Not_foundBodyButton_banner {
            type
            label
            primary {
              button_color
              button_label
              button_link {
                ...Link
              }
              button_text_color
              extra_info
              extra_info_color
              gradient_color_end
              gradient_color_start
              heading
              heading_color
            }
          }
        }
      }
    }
  }
`