import React, {Component} from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import Button from 'src/components/button'
import Link from 'src/components/link'
import DynamicStyle from 'src/components/dynamic_style'
import styles from 'src/css/slices/image_with_text_fixed.module.css'
import {classes} from 'src/utilities/classes'
import {optionalBackgroundGradient} from 'src/utilities/color'

class ImageWithTextFixed extends Component {
  get buttonId() {
    return `image-with-text-button-${this.props.sliceIndex}`
  }

  render = () => {
    const {feature_image, feature_image_mobile, text, text_color, button_label, button_link, button_color, button_text_color, gradient_color_start, gradient_color_end, image_position_right, full_width} = this.props.primary
    const background = optionalBackgroundGradient(gradient_color_start, gradient_color_end)
    return (
      <div className={classes(styles.wrapper,!full_width && styles.notFullWidth)} style={{ background }}>
        <div className={classes(styles.imageWithText, !full_width && styles.notFullWidth)} style={{ background: (full_width ? undefined : background) }}>
          <div className={classes(styles.inner,image_position_right && styles.reverse)}>
            <div className={styles.content}>
              <RichText className={styles.description} style={{color: text_color}}>{text}</RichText>
              {button_label && <Link to={button_link}>
                <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: button_text_color }, background: button_color }}>
                  <Button id={this.buttonId} withArrow>{button_label}</Button>
                </DynamicStyle>
              </Link>}
            </div>
            <div className={styles.featureImage}>
              <Image className={!!feature_image_mobile ? styles.featureImageDesktop : undefined} dpiRatio={2}>{feature_image}</Image>
              {!!feature_image_mobile && <Image className={styles.featureImageMobile} dpiRatio={2}>{feature_image_mobile}</Image>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImageWithTextFixed