import React, {Component} from "react"
import styles from 'src/css/slices/video.module.css'
import Play from 'src/images/play'

class Video extends Component {
  state = {playing: false}

  handlePlay = () => {
    this.setState({playing: true})
    this.video.play()
  }

  handleVideoEnd = () => {
    this.setState({playing: false})
    this.video.load()
  }

  render = () => {
    const {video, poster, play_button_color, play_button_opacity} = this.props.primary
    const {playing} = this.state
    return (
      <div className={styles.wrapper}>
        <div className={styles.video}>
          <div className={styles.inner}>
            <video src={video.url} poster={poster.url} playsInline="" preload="metadata"
                   onClick={this.handlePlay} ref={ref => this.video = ref} onEnded={this.handleVideoEnd}
                   controls={playing} />
            {!playing &&
              <button aria-label='Play' onClick={this.handlePlay}>
                <Play color={play_button_color} opacity={play_button_opacity}/>
              </button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Video