import { graphql } from "gatsby"
export const HowItWorks = graphql`
  fragment HowItWorks on PRISMIC_How_it_works_15ConnectionConnection {
    edges {
      node {
        _meta {
          ...Meta
        }

        sign_up_button_text
        sign_up_link { ...Link }
        online_button_text
        online_button_icon
        in_store_button_text
        in_store_button_icon

        online_disabled
        online_heading
        online_heading_color
        online_hero_image
        online_hero_image_mobile
        online_cover_image_mobile
        online_hero_background_color
        online_description_heading
        online_description
        online_sign_up_button_text
        online_sign_up_button_link { ...Link }
        online_steps {
          feature_image
          heading
          heading_color
          heading
          description
          description_color
        }

        in_store_disabled
        in_store_heading
        in_store_heading_color
        in_store_hero_image
        in_store_hero_image_mobile
        in_store_cover_image_mobile
        in_store_hero_background_color
        in_store_description_heading
        in_store_description
        in_store_sign_up_button_text
        in_store_sign_up_button_link { ...Link }
        in_store_steps {
          feature_image
          heading
          heading_color
          heading
          description
          description_color
        }

        body {
          ... on PRISMIC_How_it_works_15BodyInfo_carousel {
            type
            label
            fields {
              header_text
              text
              button_text
              button_link { ...Link }
              button_image
              background_color
              text_color
              desktop_image
              mobile_image
              control_dot_color
              control_dot_selected_color
              display_mode
            }
          }
          ... on PRISMIC_How_it_works_15BodyTiles {
            type
            label
            primary {
              button_text
              button_link { ...Link }
              tile_hover_color_1
              tile_hover_color_2
              tile_hover_color_3
            }
            fields {
              heading: header_text
              badge_text
              category_id
              order
              max_tiles
            }
          }
          ... on PRISMIC_How_it_works_15BodyFull_width_promo {
            type
            label
            primary {
              promo_image
              promo_image_mobile
              promo_url {
                ...Link
              }
            }
          }
        }

        ...HowItWorksSEO
      }
    }
  }
`