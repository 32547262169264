import React from "react"
import styles from 'src/css/slices/infinite_growth.module.css'
import LinkButton from 'src/components/link_button'
import Image from 'src/components/image'
import { RichText } from 'prismic-reactjs'

const InfiniteGrowth = ({ primary, fields }) => {
  const {
    background_color: backgroundColor,
    hero_image,
    hero_image_mobile,
    heading,
    subheading,
    description,
    button_text,
    button_link
  } = primary
  const backgroundImage = hero_image ? `url("${hero_image.url}")` : undefined
  return (
    <div className={styles.wrapper} style={{ backgroundColor, backgroundImage }}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <RichText render={heading} />
          <RichText render={subheading} />
          {hero_image_mobile && <Image className={styles.heroImageMobile}>{hero_image_mobile}</Image>}
          <div className={styles.description}>
            <RichText render={description} />
          </div>
          {button_text && <LinkButton to={button_link}>{button_text}</LinkButton>}
        </div>
        <div className={styles.right} />
      </div>
    </div>
  )
}

export default InfiniteGrowth