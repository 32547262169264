import React, {Component} from 'react'
import styles from 'src/css/slices/latest.module.css'
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import Link from 'src/components/link'
import Button from 'src/components/button'
import Carousel from 'src/components/carousel'
import CarouselDots from 'src/components/carousel_dots'
import DynamicStyle from 'src/components/dynamic_style'

class Latest extends Component {
  state = {position: 0}

  handlePositionChange = position => this.setState({position})

  render = () => {
    const {fields, primary} = this.props
    const {heading, heading_color, background_color, image} = primary
    const {position} = this.state
    return (
      <div className={styles.wrapper} style={{background: `linear-gradient(180deg,${background_color || '#d1eaef'} 75%,#fff 0)`}}>
        <div className={styles.latest}>
          <div className={styles.inner}>
            <Image dpiRatio={2} className={styles.badgeImage}>{image}</Image>
            <RichText className={styles.heading} style={{ color: heading_color }}>{heading}</RichText>
            <Carousel itemCount={fields.length} className={styles.carousel}
                      position={position} onPositionChange={this.handlePositionChange}>
              {fields.map(({merchant_image, button_label, button_link, button_color, button_text_color}, i) =>
                <div key={i}>
                  <div className={styles.merchantWrapper}>
                    <div className={styles.merchantCard}>
                      <Image dpiRatio={2}>{merchant_image}</Image>
                      <div className={styles.button}>
                        <Link to={button_link}>
                          <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: button_text_color }, background: button_color }}>
                            <Button id={`latest-button-${i}`} inverted withArrow>{button_label}</Button>
                          </DynamicStyle>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Carousel>
            <CarouselDots index={position} count={fields.length} onIndexChange={this.handlePositionChange}
                          className={styles.carouselDots}/>
          </div>
        </div>
      </div>
    )
  }
}

export default Latest