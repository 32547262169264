import React from 'react'
import ReactDOM from 'react-dom'
import styles from '../styles/modal.module.css'

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.classList.add(styles.modalWrapper)
  }

  componentDidMount() {
    this.modalRoot = document.getElementById('modal-root');
    if (!this.modalRoot) {
      const container = (document.getElementById('content') || document.body)
      if (container) {
        this.modalRoot = document.createElement('div')
        this.modalRoot.setAttribute("id", "modal-root")
        container.appendChild(this.modalRoot)
      }
    }
    this.modalRoot.appendChild(this.el);
    setTimeout(() => this.el.style.opacity = 1, 100)
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <div className={styles.mask} onMouseDown={this.props.onClose}>
        <div className={styles.modalOuter}>
          <div className={styles.modalDialog} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
            {this.props.children}
          </div>
        </div>
      </div>,
      this.el
    );
  }
}
