import styles from 'src/css/components/app_badges.module.css'
import React from 'react'
import {classes} from 'src/utilities/classes'

const AppDownload = (props) => {
  const {className, children, title='Download the app', atBottom, other} = props
  return (
    <div className={classes(className, styles.appDownload, atBottom && styles.atBottom)} {...other}>
      <h2>{title}</h2>
      <div className={styles.appBadges}>
        {children}
      </div>
    </div>
  )
}

export default AppDownload
